import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Client, ClientFacadeService } from 'ssotool-app/+client/store';
import {
  DialogComponent,
  PermissionChecker,
  SSOToolRoutePathService,
  UserStateManagerService,
} from 'ssotool-app/shared';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ClientFormComponent } from '../client-form';
import { ShareClientComponent } from '../share-client';

@UntilDestroy()
@Component({
  selector: 'sso-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss'],
})
export class ClientHeaderComponent implements OnInit {
  @Input() set isSideNavVisible(isVisible) {
    this.showClientDetails$.next(isVisible);
  }
  @ViewChild('description', { read: ElementRef, static: false })
  descriptionElement: ElementRef;

  clientTooltipConfig = {
    trigger: 'click',
    followCursor: false,
    arrow: false,
    placement: 'bottom-start',
    animation: 'shift-away',
    interactive: true,
    maxWidth: '600px',
  };

  clientActionsTooltipConfig = {
    followCursor: false,
    placement: 'right',
    animation: 'shift-away',
    interactive: true,
    theme: 'transparent',
    arrow: false,
  };

  selectedClient$ = this.clientFacade.selectActiveClientData$;
  createdBy$ = combineLatest([
    this.selectedClient$,
    this.userManagerService.dataList$,
  ]).pipe(
    filter(([client]) => !!client),
    map(([client]) => this.userManagerService.get(client.owner)),
    filter((user) => user?.loaded),
  );

  createdByName$ = this.createdBy$.pipe(
    map((user) => `${user?.firstName} ${user?.lastName}`),
  );

  isAdmin$ = this.selectedClient$.pipe(
    map((client) => this.permissionChecker.isAdmin(client?.permissions)),
  );

  recentClients$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);

  showClientDetails$ = new BehaviorSubject(true);
  get showClientDetails() {
    return this.showClientDetails$.value;
  }
  showMoreDescription$ = new BehaviorSubject(false);
  get showMore() {
    return this.showMoreDescription$.value;
  }
  set showMore(value: boolean) {
    this.showMoreDescription$.next(value);
  }

  _ongoingDuplicateList = new BehaviorSubject<string[]>([]);

  ongoingDuplicateList$ = this._ongoingDuplicateList
    .asObservable()
    .pipe(untilDestroyed(this));

  isDuplicating$: Observable<boolean> = combineLatest([
    this.selectedClient$,
    this.ongoingDuplicateList$,
  ]).pipe(map(([client, list]) => list.includes(client?.clientId)));

  canDuplicate$: Observable<boolean> = combineLatest([
    this.isDuplicating$,
    this.isAdmin$,
  ]).pipe(map(([isDuplicating, isAdmin]) => !!!isDuplicating && !!isAdmin));

  @HostListener('window:resize') onResize() {
    this.changeDetector.detectChanges();
  }

  constructor(
    private clientFacade: ClientFacadeService,
    private dialog: MatDialog,
    private permissionChecker: PermissionChecker,
    private userManagerService: UserStateManagerService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pathService: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.clientFacade.selectClientList$('active'),
      this.clientFacade.activeClientId$,
    ]).subscribe(([clientList, activeClientId]) => {
      let unselectedActiveClients = clientList.filter(
        (client) => client.clientId !== activeClientId,
      );

      unselectedActiveClients.sort((a, b) => {
        let dateA = Date.parse(a.updatedAt);
        let dateB = Date.parse(b.updatedAt);

        return dateB - dateA;
      });

      this.recentClients$.next(unselectedActiveClients.slice(0, 3));
    });

    this.initializeDuplicatePref();
  }

  onArchive() {
    this.selectedClient$
      .pipe(
        take(1),
        mergeMap((client) =>
          this.dialog
            .open(DialogComponent, {
              data: {
                title: 'Archive Client',
                message: `Are you sure you want to archive the client ${client.name}?`,
                confirm: 'Archive',
                close: 'Cancel',
                disableClose: false,
                width: '250px',
                client,
              },
            })
            .afterClosed(),
        ),
      )
      .subscribe((data) => {
        if (data?.client) {
          this.clientFacade.setClientStatus(data.client.clientId, 'archive');
          this.navigateToClientList();
        }
      });
  }

  onShare(): void {
    this.selectedClient$
      .pipe(
        take(1),
        mergeMap((data) =>
          this.dialog
            .open(ShareClientComponent, {
              data,
            })
            .afterClosed(),
        ),
      )
      .subscribe();
  }

  onEdit() {
    this.selectedClient$
      .pipe(
        take(1),
        mergeMap((client) =>
          this.dialog
            .open(ClientFormComponent, {
              data: {
                client,
                mode: 'update',
                title: `Edit ${client.name}`,
              },
            })
            .afterClosed(),
        ),
      )
      .subscribe();
  }

  onDuplicate() {
    this.selectedClient$
      .pipe(
        take(1),
        mergeMap((client) =>
          this.dialog
            .open(DialogComponent, {
              data: {
                title: 'Duplicate Client',
                message: `Are you sure you want to duplicate the client ${client.name}?`,
                confirm: 'Duplicate',
                close: 'Cancel',
                disableClose: false,
                width: '250px',
                client,
              },
            })
            .afterClosed(),
        ),
      )
      .subscribe((data) => {
        if (data?.client) {
          this.clientFacade.duplicateClient(data.client.clientId);
        }
      });
  }

  isShowMoreVisible() {
    if (!this.descriptionElement) {
      return;
    }

    const element = this.descriptionElement?.nativeElement as HTMLElement;
    const isOverflowing =
      element.clientWidth < element.scrollWidth ||
      element.clientHeight < element.scrollHeight;
    return isOverflowing;
  }

  navigateToClientList() {
    this.router.navigate(this.pathService.clients());
  }

  clientClicked(client: Client) {
    this.router.navigate(this.pathService.clients(), {
      relativeTo: this.activatedRoute,
      queryParams: {
        quickSwitchId: client.clientId,
        quickSwitchImport: client.hasSuccessfulImport,
        quickSwitchName: client.name,
      },
    });
  }

  redirectToClientsPage() {
    this.router.navigate(this.pathService.clients());
  }

  private initializeDuplicatePref() {
    this.userManagerService
      .getCurrentUserDuplicateClientsPref()
      .pipe(untilDestroyed(this))
      .subscribe((list) => {
        this._ongoingDuplicateList.next(list);
      });
  }
}

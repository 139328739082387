import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

export function getProcessLabelForCampaign(): string {
  if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE_R2)) {
    return 'Campaign.labels.parameters.energyProcessId';
  } else {
    return 'Campaign.labels.parameters.processId';
  }
}

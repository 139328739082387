import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientFacadeService, ExistingConverter } from 'ssotool-app/+client';
import {
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
} from 'ssotool-app/app.references';
import { BaseFormComponent } from 'ssotool-app/shared/component/base-form/base-form.component';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import {
  convertToYearlyValues,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  getEndYear,
  getStartYear,
} from 'ssotool-shared';

import { Component, Inject, OnInit, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  DetailsDrawerService,
  OCULUS_DETAILS_DRAWER_DATA,
} from '@oculus/components/details-drawer';

import { getProcessLabel } from '../existing-drawer.utils';
import {
  ExistingConverterDrawerData,
  ExistingConverterDrawerMode,
} from './existing-converter-drawer.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-existing-converter-drawer',
  templateUrl: './existing-converter-drawer.component.html',
  styleUrls: ['./existing-converter-drawer.component.scss'],
})
export class ExistingConverterDrawerComponent
  extends BaseFormComponent
  implements OnInit
{
  startYear = DEFAULT_START_YEAR;
  endYear = DEFAULT_END_YEAR;
  baseForm: FormGroup = this.formBuilder.group({
    converterId: '',
    clientId: '',
    owner: '',
    name: ['', Validators.required],
    companyEntityId: ['', Validators.required],
    geoId: '',
    geography: '',
    siteId: '',
    siteName: '',
    sectorId: '',
    processId: ['', Validators.required],
    scalingFactorName: '',
    startYear: DEFAULT_START_YEAR,
    endYear: DEFAULT_END_YEAR,
    productionFluids: [null, Validators.required],
    consumptionFluids: [null, Validators.required],
    mainConsumptionFluidId: ['', Validators.required],
    mainProductionFluidId: ['', Validators.required],
    minOperatingHrs: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    capacity: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    technicalLife: [
      convertToYearlyValues('10', this.startYear, this.endYear),
      Validators.required,
    ],
    depreciationTime: [
      convertToYearlyValues('10', this.startYear, this.endYear),
      Validators.required,
    ],
    foM: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    fomPerInstall: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
  });
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Entities.messages.errors');
  fluidOptions$ = new Observable<FormFieldOption<string>[]>();
  geoOptions$ = new Observable<FormFieldOption<string>[]>();
  companyOptions$ = new Observable<FormFieldOption<string>[]>();
  sectorOptions$ = new Observable<FormFieldOption<string>[]>();
  processOptions$ = new Observable<FormFieldOption<string>[]>();

  private _mode = new BehaviorSubject<ExistingConverterDrawerMode>(
    ExistingConverterDrawerMode.VIEW,
  );
  mode$ = this._mode.asObservable();

  readonly$ = this.mode$.pipe(
    startWith(ExistingConverterDrawerMode.VIEW),
    map(
      (mode) => this.data.readonly || mode === ExistingConverterDrawerMode.VIEW,
    ),
  );
  loading$ = this.clientFacade.dataLoading$(this.data.clientId);

  private _geographyMode = new BehaviorSubject<string>('geoId');
  geographyMode$ = this._geographyMode.asObservable();

  isFeatureSwitched = isFeatureEnabled(
    FeatureFlag.INPUT_SIMPLIFICATION_FEATURE,
  );

  processLabel = getProcessLabel();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    @Inject(OCULUS_DETAILS_DRAWER_DATA)
    public data: ExistingConverterDrawerData,
    public formBuilder: FormBuilder,
    private _formService: FormService,
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {
    super(ngControl);
  }

  get isPristine() {
    return this.baseForm.pristine;
  }

  get hasScalingFactor(): boolean {
    return !!this.baseForm.controls.scalingFactorName.value;
  }

  ngOnInit() {
    super.ngOnInit();
    this._mode.next(this.data.mode);

    this.initForm();
    this.initFieldOptions();
  }

  initForm() {
    let initializedForm = {
      clientId: this.data.clientId,
    } as Partial<ExistingConverter>;
    if (
      this.data?.existingConverter &&
      this.data.mode !== ExistingConverterDrawerMode.CREATE
    ) {
      initializedForm = {
        ...initializedForm,
        ...this.data.existingConverter,
      };
    }
    this.baseForm.patchValue(initializedForm, { emitEvent: false });
    this._geographyMode.next(
      this.baseForm.controls['geoId'].value ? 'geoId' : 'siteId',
    );
  }

  /**
   * Initialized fields that has reference to the client entities.
   */
  private initFieldOptions() {
    const selectorKey = this.data.clientId;
    this.geoOptions$ =
      this.data?.existingConverter?.geoId &&
      this.data?.existingConverter?.geoId.length
        ? this.createFormOptions(
            this.clientFacade.selectGeosAndSites$,
            selectorKey,
            'geoId',
          )
        : this.createFormOptions(
            this.clientFacade.selectGeosAndSites$,
            selectorKey,
            'siteId',
          );
    this.companyOptions$ = this.createFormOptions(
      this.clientFacade.selectCompanies$,
      selectorKey,
      'companyEntityId',
    );
    this.sectorOptions$ = this.createFormOptions(
      this.clientFacade.selectSectors$,
      selectorKey,
    );
    this.processOptions$ = this.createFormOptions(
      this.clientFacade.selectProcesses$,
      selectorKey,
    );
    this.fluidOptions$ = this.createFormOptions(
      this.clientFacade.selectFluids$,
      selectorKey,
    );
  }

  onClose(): void {
    this.drawerService.close();
  }

  setMainConsumption(id: string) {
    this.baseForm.controls.mainConsumptionFluidId.patchValue(id);
  }

  setMainProduction(id: string) {
    this.baseForm.controls.mainProductionFluidId.patchValue(id);
  }

  getStartYear(value: Record<string, string>): string {
    return getStartYear(value);
  }

  getEndYear(value: Record<string, string>): string {
    return getEndYear(value);
  }
}

import { Observable, Observer, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  skipWhile,
  switchMap,
} from 'rxjs/operators';
import {
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
} from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared/helpers';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import { generateEndpoint } from 'ssotool-core/utils';
import { download } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import {
  COMPANY_ENTITY_TYPE,
  COMPANY_GROUPS_KEY,
  GEO_ENTITY_TYPE,
  GEO_GROUPS_KEY,
  GROUP_KEY,
  HID_KEY,
  NAME_KEY,
} from '../client.const';
import {
  BackendRunSettings,
  Client,
  ClientDataEntity,
  ClientDataInfo,
  ClientShareInfo,
  ClientStatus,
  ClientTreeRecord,
  ClientTreeReference,
  Geography,
  Groups,
  RunSettings,
  Site,
} from '../store/client.model';

@Injectable()
export class ClientAPIService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  create(name: string, description?: string): Observable<any> {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.create,
        ),
        { name, description },
      )
      .pipe(
        map((response) => this.mapClientToFrontend(response)),
        catchError((error) => throwError('error')),
      );
  }

  get(id: string): Observable<Client> {
    return this.http
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.getClient,
          id,
        ),
      )
      .pipe(
        map((response) => this.mapClientToFrontend(response)),
        catchError((error) => throwError(error)),
      );
  }

  update(
    clientId: string,
    name: string,
    description?: string,
  ): Observable<any> {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.update,
          clientId,
        ),
        {
          name,
          description,
        },
      )
      .pipe(
        map((response) => this.mapClientToFrontend(response)),
        catchError((error) => throwError(error)),
      );
  }

  getList(): Observable<Client[]> {
    return this.http
      .get<Client[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.getClientList,
        ),
      )
      .pipe(
        map((response) =>
          response.map((client) => this.mapClientToFrontend(client)),
        ),
        catchError((error) => throwError(error)),
      );
  }

  getArchivedlist(): Observable<Client[]> {
    return this.http
      .get<Client[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.getClientList,
        ),
        {
          params: {
            isArchive: 'True',
          },
        },
      )
      .pipe(
        map((response) =>
          response.map((client) => this.mapClientToFrontend(client)),
        ),
        catchError((error) => throwError(error)),
      );
  }

  share(clientId: string, shareInfo: ClientShareInfo[]): Observable<any> {
    return this.http
      .put(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.share,
          clientId,
        ),
        {
          usersAccess: shareInfo,
        },
      )
      .pipe(
        map(() => this.mapShareInfoToClient(shareInfo, clientId)),
        catchError((error) => throwError(error)),
      );
  }

  getClientShareInfo(clientId: string) {
    return this.http
      .get<ClientShareInfo[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.getUsers,
          clientId,
        ),
      )
      .pipe(
        map((response) => this.mapShareInfoToClient(response, clientId)),
        catchError((error) => throwError(error)),
      );
  }

  getClientDataInfo(clientId: string) {
    return this.http
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.getClientData,
          clientId,
        ),
      )
      .pipe(
        mergeMap((clientDataSignedUrl: any) => {
          if (!clientDataSignedUrl) {
            return of({ clientId, clientData: undefined });
          }

          return this.http
            .get(clientDataSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              skipWhile((downloadedData) => !downloadedData['content']),
              switchMap(
                (response) =>
                  new Observable((observer: Observer<any>) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      observer.next(reader.result);
                      observer.complete();
                    };
                    reader.readAsText(response.content);
                  }),
              ),
              map((data: string) => ({
                clientId,
                clientData: this.mapClientDataToFrontend(
                  JSON.parse(data) as ClientDataInfo,
                ),
              })),
            );
        }),
      );
  }

  delete(clientId: string) {
    return this.http
      .delete<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.delete,
          clientId,
        ),
      )
      .pipe(
        map((response) => ({ clientId })),
        catchError((error) => throwError(error)),
      );
  }

  archive(clientId: string, mode: ClientStatus): Observable<any> {
    return this.http
      .post<string[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.archiveClient,
          clientId,
        ),
        {
          mode: mode === 'active' ? 'unarchive' : mode,
        },
      )
      .pipe(catchError((error) => throwError(error)));
  }

  duplicateClient(clientId: string): Observable<Client> {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.clients.duplicateClient,
          clientId,
        ),
        {},
      )
      .pipe(catchError((error) => throwError(error)));
  }

  mapClientToFrontend(data: any): Client {
    return {
      clientId: data.clientId,
      name: data.name,
      owner: data.owner,
      description: data.description,
      createdAt: this.formatDate(data.createdAt),
      updatedAt: this.formatDate(data.updatedAt),
      dataType: data.dataType,
      permissions: data.permissions,
      sharedCount: data.sharedCount || 0,
      templateVersion: data.templateVersion,
      campaignTemplateVersion: data.campaignTemplateVersion,
      inEligibleForComputation: data?.ineligibleForComputation
        ? JSON.parse(data?.ineligibleForComputation)
        : undefined,
      isArchive: !!data?.isArchive,
      runSettings: this.mapRunSettingsToFrontend(data?.runSettings),
      hasSuccessfulImport: data?.hasSuccessfulImport,
      isSandbox: !!data?.isSandbox,
      roadmapTemplateVersion: data?.roadmapTemplateVersion || '1.0',
    };
  }

  mapRunSettingsToFrontend(
    backendRunSettings: BackendRunSettings,
  ): RunSettings {
    return {
      ...backendRunSettings,
      defaultStartYear:
        backendRunSettings?.fromYear?.toString() ||
        DEFAULT_START_YEAR?.toString(),
      defaultEndYear:
        backendRunSettings?.toYear?.toString() || DEFAULT_END_YEAR?.toString(),
    };
  }

  formatDate(dateString: string) {
    return formatDate(dateString, 'dd MMM YYYY HH:mm', this.locale);
  }

  mapShareInfoToClient(data: ClientShareInfo[], clientId: string) {
    return {
      clientId,
      shareInfo: data,
    };
  }

  // remove or not the coerce after deprecating old client format (w/o sites)
  mapClientDataToFrontend(data: ClientDataInfo): ClientDataInfo {
    const trees = {
      geoTree: isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
        ? this.buildTreev7(data.geos, Coerce.toEmptyObject(data.sites))
        : this.buildTree(data.geos),
      companyTree: this.buildTree(data.companies),
    };

    let geoFlatMemberNames = {};

    if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)) {
      const geos = data.geos;
      const sites = Coerce.toEmptyObject(data.sites);

      geoFlatMemberNames = this.createFlatMembersNamesV7(
        geos,
        sites,
        geoFlatMemberNames,
      );
    } else {
      this.createFlatMemberNames(trees.geoTree, data.geos);
    }

    return {
      ...data,
      ...trees,
      geoHierarchy: isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
        ? this.getHierarchyV7(data.geos, GEO_ENTITY_TYPE)
        : this.getHierarchy(data.geos, GEO_ENTITY_TYPE),
      companyHierarchy: this.getHierarchy(data.companies, COMPANY_ENTITY_TYPE),
      [GEO_GROUPS_KEY]: this.getGroups(data.geos, GEO_ENTITY_TYPE),
      [COMPANY_GROUPS_KEY]: this.getGroups(data.companies, COMPANY_ENTITY_TYPE),
      geoFlatMemberNames: geoFlatMemberNames,
      companyFlatMemberNames: this.createFlatMemberNames(
        trees.companyTree,
        data.companies,
      ),
      ...this.patchSitesWithParentHIDs(
        data.geos,
        Coerce.toEmptyObject(data.sites),
      ),
    };
  }

  private createFlatMembersNamesV7 = (
    geos: ClientDataEntity<Geography>,
    sites: ClientDataEntity<Site>,
    geoFlatMemberNames: {},
  ) => {
    Object.values(geos).forEach((geo) => {
      const geoName = geo.name;
      const parentName = geo.parentName;

      if (!geoFlatMemberNames[geoName]) {
        geoFlatMemberNames[geoName] = [geoName];
      }

      if (parentName) {
        if (!geoFlatMemberNames[parentName]) {
          geoFlatMemberNames[parentName] = [parentName];
        }
        geoFlatMemberNames[parentName].push(geoName);
      }
    });

    Object.values(sites).forEach((site) => {
      const geoName = site.geoName;
      const siteName = site.name;

      if (!geoFlatMemberNames[siteName]) {
        geoFlatMemberNames[siteName] = [siteName];
      }

      if (!geoFlatMemberNames[geoName]) {
        geoFlatMemberNames[geoName] = [geoName];
      }

      geoFlatMemberNames[geoName].push(siteName);
    });

    // Add all regions, countries and sites to World
    geoFlatMemberNames['World'] = ['World'];
    Object.keys(geoFlatMemberNames).forEach((key) => {
      if (key !== 'World') {
        geoFlatMemberNames['World'].push(...geoFlatMemberNames[key]);
      }
    });

    return geoFlatMemberNames;
  };

  private patchSitesWithParentHIDs(
    geos: ClientDataEntity<Geography>,
    sites: ClientDataEntity<Site>,
  ): Record<string, ClientDataEntity<Site>> {
    if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)) {
      let sitesWithHIDs = Object.entries(sites).reduce(
        (newSites, [siteId, site]) => {
          newSites[siteId] = {
            ...site,
            parentHid: geos[site.geoId].hId,
          };
          return newSites;
        },
        {} as ClientDataEntity<Site>,
      );
      return {
        sites: sitesWithHIDs,
      };
    } else {
      return {};
    }
  }

  getHierarchy(entities: ClientDataEntity, key: string) {
    const sorted = Coerce.getObjValues(entities).sort((a, b) =>
      a?.['level'] || 0 > b?.['level'] || 0 ? -1 : 1,
    );

    return sorted.reduce((acc, entity) => {
      const entityType = entity[key];
      const hId = entity[HID_KEY];
      const name = entity[NAME_KEY];

      if (!acc.hasOwnProperty(entityType)) {
        acc[entityType] = {};
      }
      acc[entityType][name] = hId;

      return acc;
    }, {});
  }

  getHierarchyV7(entities: ClientDataEntity, key: string) {
    const geoLevelMapping = {
      0: 'Geography',
      1: 'Region',
      2: 'Country',
      3: 'Site',
    };

    const companyLevelMapping = {
      0: 'Company',
      1: 'Type of site',
    };

    const sorted = Coerce.getObjValues(entities).sort((a, b) =>
      a?.['level'] || 0 > b?.['level'] || 0 ? -1 : 1,
    );

    const mapping =
      key === GEO_ENTITY_TYPE ? geoLevelMapping : companyLevelMapping;

    return sorted.reduce((acc, entity) => {
      const entityType = entity[key] ? entity[key] : mapping[entity.level];
      const hId = entity[HID_KEY];
      const name = entity[NAME_KEY];

      if (!acc.hasOwnProperty(entityType)) {
        acc[entityType] = {};
      }
      acc[entityType][name] = hId;

      return acc;
    }, {});
  }

  /**
   * Generates an object of groups grouped by entity type
   * @param entity - the client data entity as data source
   * @param entityType - the entity type for grouping
   * @returns an object of groups grouped by entity type
   */
  getGroups(
    entity: ClientDataEntity,
    entityType: string,
    groupKey = GROUP_KEY,
  ): Groups {
    if (!entity || Coerce.getObjKeys(entity).length === 0 || !entityType) {
      return {};
    }

    return Coerce.getObjValues(entity).reduce<
      Record<string, Record<string, string[]>>
    >((groupedGroups, currentEntity) => {
      const type = currentEntity[entityType];
      const group = currentEntity[groupKey];
      const hId = currentEntity[HID_KEY];

      if (!groupedGroups.hasOwnProperty(type)) {
        groupedGroups[type] = {};
      }
      if (!group || group.split(';').length > 1) {
        return groupedGroups;
      }
      if (!groupedGroups[type].hasOwnProperty(group)) {
        groupedGroups[type][group] = [];
      }
      groupedGroups[type][group] = groupedGroups[type][group].concat(hId);
      return groupedGroups;
    }, {});
  }

  buildTree(entities: ClientDataEntity): ClientTreeReference {
    return Object.entries(entities || {}).reduce<ClientTreeReference>(
      (acc, [id, geo]) => {
        const hIds = geo.hId?.split('-');
        acc.hids[id] = hIds;

        let prev = acc.tree;
        hIds.forEach((hId) => {
          if (!prev[hId]) {
            prev[hId] = { ...geo };
            prev[hId].children = {};
          }

          prev = prev[hId].children;
        });

        return acc;
      },
      { tree: { children: null }, hids: {} },
    );
  }

  buildTreev7(
    entities: ClientDataEntity,
    sites: ClientDataEntity<Site> = {},
  ): ClientTreeReference {
    return Object.entries(entities || {}).reduce<ClientTreeReference>(
      (acc, [id, geo]) => {
        const hIds = geo.hId?.split('-');
        acc.hids[id] = hIds;

        let prev = acc.tree;
        hIds.forEach((hId) => {
          let children = [];
          if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)) {
            children = this.populateChildrenSites(sites, geo);
            Object.values(children).forEach((child) => {
              acc.hids[child.siteId] = acc.hids[id].concat(child.hId);
            });
          }
          if (!prev[hId]) {
            prev[hId] = { ...geo };
            prev[hId].children = children;
          }

          prev = prev[hId].children;
        });

        return acc;
      },
      { tree: { children: null }, hids: {} },
    );
  }

  private populateChildrenSites(
    sites: ClientDataEntity<Site>,
    geography: Geography,
  ): any {
    return Object.values(sites).reduce((transformedSites, site) => {
      if (site.geoId === geography.geoId) {
        const newSite = this.transformSiteToChild(
          site as Site,
          geography.name,
          Object.values(transformedSites).length,
        );
        transformedSites[newSite.hId] = newSite;
      }
      return transformedSites;
    }, []);
  }

  private transformSiteToChild(
    site: Site,
    geographyName: string,
    index: number,
  ) {
    return {
      name: site.name,
      parentId: site.geoId,
      hId: index.toString().padStart(3, '0'),
      level: 100,
      parentName: geographyName,
      siteId: site.siteId,
      geoId: site.siteId,
      children: [],
    };
  }

  private createFlatMemberNames(
    treeRef: ClientTreeReference,
    entities: ClientDataEntity,
  ): Record<string, string[]> {
    return {
      ...this.createHierarchyFlatMemberNames(treeRef?.tree?.['000'])[0],
      ...this.createGroupFlatMemberNames(entities),
    };
  }

  private createHierarchyFlatMemberNames(
    tree: ClientTreeRecord,
    container: Record<string, string[]> = {},
  ): [Record<string, string[]>, string[]] {
    const name = tree?.name;
    container[name] = [name];
    if (!Coerce.getObjKeys(tree.children).length) {
      return [container, container[name]];
    }

    container[tree?.name] = container[name].concat(
      Coerce.getObjValues(tree.children).reduce(
        (acc, curr) =>
          acc.concat(this.createHierarchyFlatMemberNames(curr, container)[1]),
        [],
      ),
    );

    return [container, container[name]];
  }

  private createGroupFlatMemberNames(
    entities: ClientDataEntity,
  ): Record<string, string[]> {
    return Coerce.getObjValues(entities).reduce((acc, entity) => {
      const groups: string[] = entity?.group?.split(';');
      const name: string = entity?.name;

      if (!groups?.length) {
        return acc;
      }

      groups.forEach((group) => {
        if (acc[group]) {
          acc[group].push(name);
        } else {
          acc[group] = [group, name];
        }
      });

      return acc;
    }, {});
  }
}

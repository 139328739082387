import { combineLatest, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { retrieveParentHids } from 'ssotool-core/utils';
import {
  Coerce,
  distinctObject,
  FiltersService,
  FilterWithCondition,
  GROUP_INDICATOR,
  OtherConditionFunction,
} from 'ssotool-shared';
import {
  FiltersDialogConditionMode,
  FiltersDialogConfiguration,
} from 'ssotool-shared/modules/filters-dialog/filters-dialog.model';
import { FilterSettings } from 'ssotool-shared/modules/filters/filters.model';

import { Injectable } from '@angular/core';

import { COMPANY_KEY, GEOGRAPHY_KEY } from '../client.const';
import { ClientFacadeService } from '../store/client.facade.service';
import {
  ClientOtherFilters,
  GroupUnitEntity,
  Hierarchy,
} from '../store/client.model';

type LevelFilterDatum = Readonly<{
  name: string; // Name of an entity
  hidKey: string;
  groupKey: string;
  multiple?: boolean;
  parentStream?: boolean;
  childStream?: boolean;
}>;

type HidDatum = Readonly<{
  levelHid: string;
  parentHids: string[];
}>;

export type ClientFilterFunctionDatum<T = any> = Readonly<{
  data: T[];
  ids: string[];
}>;

@Injectable()
export class ClientFiltersService extends FiltersService {
  dialogConfig: FiltersDialogConfiguration = {
    conditionMode: {
      [GEOGRAPHY_KEY]: FiltersDialogConditionMode.WITH_GROUP,
      [COMPANY_KEY]: FiltersDialogConditionMode.WITH_GROUP,
    },
  };
  constructor(private clientFacade: ClientFacadeService) {
    super();
  }

  getFilterOptions(
    clientId: string,
    otherFilters: ClientOtherFilters[] = [],
    settings: FilterSettings = {},
  ) {
    return combineLatest([
      this.clientFacade.selectFilterOptionsWithHierarchy(clientId).pipe(
        map((options) =>
          Object.entries(options).reduce((acc, [key, value]) => {
            acc[settings?.keyMapper?.[key] || key] = value;
            return acc;
          }, {}),
        ),
      ),
      ...otherFilters.map((filter) =>
        filter.obs.pipe(
          map((f) =>
            this.createFilterOptions(
              filter.attributes,
              f,
              filter.labelMapper,
              filter.convertToString,
              settings,
            ),
          ),
        ),
      ),
    ]).pipe(
      map(([hierarchy, ...others]) =>
        others
          .concat(!!settings.noHierarchy ? [] : [hierarchy])
          .reduce((acc, filter) => ({ ...acc, ...filter }), {}),
      ),
      distinctObject,
    );
  }

  getFilterControlFunction<T>(
    clientId: string,
    data: T[],
    filters: FilterWithCondition,
    levelFilter?: LevelFilterDatum,
    settings: FilterSettings = {},
    otherConditions: OtherConditionFunction<T>[] = [],
  ): Observable<ClientFilterFunctionDatum> {
    return combineLatest([
      this.clientFacade.selectFilterGroupedNames(clientId).pipe(distinctObject),
      this.clientFacade.selectGeoHierarchy$(clientId).pipe(distinctObject),
      this.clientFacade.selectGeographyGroups$(clientId).pipe(distinctObject),
    ]).pipe(
      map(([isPartOfReferences, hierarchy, groups]) => {
        const otherConditionFunctions: OtherConditionFunction<T>[] = [
          ...otherConditions,
        ];

        this.createFilterLevel<T>(
          [hierarchy, groups],
          levelFilter,
          otherConditionFunctions,
        );

        const filteredData: T[] = this.filterObjectsWithCondition<T>(
          data,
          filters,
          otherConditionFunctions,
          {
            valueIndicators: [GROUP_INDICATOR],
            isPartOfReferences,
            ...settings,
          },
        );

        return {
          data: filteredData,
          ids:
            filteredData?.map((datum) => datum?.[settings.idKey || 'id']) || [],
        };
      }),
    );
  }

  private createFilterLevel<T>(
    [hierarchy, group]: [Hierarchy, GroupUnitEntity],
    levelFilter: LevelFilterDatum,
    container: OtherConditionFunction<T>[],
  ): void {
    if (!levelFilter?.name) {
      return;
    }

    const hidDatum: HidDatum = this.getHids(hierarchy, levelFilter);
    const fn = levelFilter.name.includes(GROUP_INDICATOR)
      ? this.createLevelGroupFilterFunction<T>(group, levelFilter)
      : this.createLevelFilterFunctions<T>(levelFilter, hidDatum);

    container.push(fn);
  }

  private getHids(
    hierarchy: Hierarchy,
    levelFilter: LevelFilterDatum,
  ): HidDatum {
    const levelHid = Coerce.getObjValues(hierarchy).find((hmap) =>
      Coerce.getObjKeys(hmap).includes(levelFilter.name),
    )?.[levelFilter.name];

    return { levelHid, parentHids: retrieveParentHids(levelHid) };
  }

  private createLevelGroupFilterFunction<T>(
    group: GroupUnitEntity,
    levelFilter: LevelFilterDatum,
  ): OtherConditionFunction<T> {
    const parsedGroupName = levelFilter.name?.replace(GROUP_INDICATOR, '');
    const groupMemberNames = Coerce.getObjValues(group).find(
      (groupUnit) => groupUnit.name === parsedGroupName,
    )?.names;

    return (datum: T) =>
      groupMemberNames
        .concat(parsedGroupName)
        .includes(datum?.[levelFilter?.groupKey]);
  }

  private createLevelFilterFunctions<T>(
    levelFilter: LevelFilterDatum,
    hidDatum: HidDatum,
  ): OtherConditionFunction<T> {
    return (datum: T) =>
      levelFilter.multiple
        ? this.createLevelFilterMultipleFunction<T>(
            datum,
            levelFilter,
            hidDatum,
          )
        : this.createLevelFilterSingleFunction<T>(datum, levelFilter, hidDatum);
  }

  private createLevelFilterMultipleFunction<T>(
    datum: T,
    levelFilter: LevelFilterDatum,
    { levelHid, parentHids }: HidDatum,
  ): boolean {
    return datum?.[levelFilter.hidKey]?.some(
      (hid: string) =>
        hid &&
        ((levelFilter.parentStream && parentHids.includes(hid)) ||
          (levelFilter.childStream && hid.startsWith(levelHid)) ||
          hid === levelHid),
    );
  }

  private createLevelFilterSingleFunction<T>(
    datum: T,
    levelFilter: LevelFilterDatum,
    { levelHid, parentHids }: HidDatum,
  ): boolean {
    return (
      (levelFilter.parentStream &&
        parentHids.includes(datum?.[levelFilter.hidKey])) ||
      (levelFilter.childStream &&
        datum?.[levelFilter.hidKey]?.startsWith(levelHid)) ||
      datum?.[levelFilter.hidKey] === levelHid
    );
  }
}

import { EnvConfig } from 'ssotool-core/config';
import { endpoints, version } from './environment-commons';

export const environment: EnvConfig = {
  name: 'dev',
  production: false,
  cognito: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_nHyxZSD6H',
      userPoolWebClientId: 's84tia7ho6iramgrmqcfs5egi',
      userPoolWebClientSecret: '',
      oauth: {
        domain: 'ssotool-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://dev.ssotool.te-ded.com/clients',
        redirectSignOut: 'https://dev.ssotool.te-ded.com/home',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: true
        }
      }
    }
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401]
  },
  api: {
    baseUrl: "https://dev.ssotool.te-ded.com/",
    endpoints
  },
  version,
  websocket: {
    url: "wss://wss.dev.ssotool.te-ded.com/",
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000
  },
  sentryDsn: 'https://45a7e1656e1a452caff0136261f86c7a@o258615.ingest.sentry.io/5281783',
  sentryRelease: `ssotool-${version}`,
  homeUrl: 'https://dev.ssotool.te-ded.com/login',
  faqUrl: null,
  trainingUrl: null
};
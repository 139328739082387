<form
  data-testid="form-container"
  fxLayout="column"
  fxLayoutGap="20px"
  [formGroup]="baseForm"
  #form
>
  <div fxLayout="column" fxLayoutGap="10px">
    <!-- General Parameters -->
    <div class="general-container" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon
          fxFlex="18px"
          class="campaign-type-icon"
          data-testid="campaign-type-icon"
          [ngStyle]="{ color: COLOR[campaignType] }"
          [matTooltip]="campaignType"
        >
          circle
        </mat-icon>
        <!-- Name -->
        <sso-input
          class="full-width"
          size="large"
          data-testid="input-name-field"
          [placeholder]="'Campaign.placeholders.parameters.name' | translate"
          [label]="'Campaign.labels.parameters.name' | translate"
          [formControl]="baseForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages?.name"
          [readonly]="readonly"
          appearance="fill"
        ></sso-input>
      </div>
      <mat-divider></mat-divider>

      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- SubType -->
        <sso-input
          data-testid="input-subtype-field"
          [placeholder]="'Campaign.placeholders.parameters.subtype' | translate"
          [label]="'Campaign.labels.parameters.subtype' | translate"
          [formControl]="baseForm?.controls?.subtype"
          [required]="true"
          [errorMessageMap]="errorMessages?.subtype"
          [readonly]="readonly"
        ></sso-input>
        <!-- Category -->
        <sso-input
          data-testid="input-category-field"
          [placeholder]="
            'Campaign.placeholders.parameters.category' | translate
          "
          [label]="'Campaign.labels.parameters.category' | translate"
          [formControl]="baseForm?.controls?.category"
          [required]="true"
          [errorMessageMap]="errorMessages?.category"
          [readonly]="readonly"
        ></sso-input>
        <!-- Roadmap -->
        <sso-input
          data-testid="input-roadmap-field"
          [placeholder]="'Campaign.placeholders.parameters.roadmap' | translate"
          [label]="'Campaign.labels.parameters.roadmap' | translate"
          [formControl]="baseForm?.controls?.pathway"
          [required]="true"
          [errorMessageMap]="errorMessages?.roadmap"
          [readonly]="readonly"
        ></sso-input>
        <!-- Original EE label -->
        <sso-select
          data-testid="input-origEELabel-field"
          [label]="'Campaign.labels.parameters.origEELabel' | translate"
          [options]="energyEfficiencyLabelOptions"
          [control]="baseForm?.controls?.origEELabel"
          [errorMessageMap]="errorMessages?.origEELabel"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Final EE label -->
        <sso-select
          data-testid="input-finalEELabel-field"
          [label]="'Campaign.labels.parameters.finalEELabel' | translate"
          [options]="energyEfficiencyLabelOptions"
          [control]="baseForm?.controls?.finalEELabel"
          [errorMessageMap]="errorMessages?.finalEELabel"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Renewal Strategy -->
        <sso-select
          data-testid="input-renewalStrategy-field"
          [label]="'Campaign.labels.parameters.renewalStrategy' | translate"
          [options]="renewalStrategyOptions"
          [control]="baseForm?.controls?.renewalStrategy"
          [errorMessageMap]="errorMessages?.renewalStrategy"
          [required]="true"
          [readonly]="readonly"
        ></sso-select>
      </div>
    </div>

    <!-- Associated Entities -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.associatedEntities' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- Geography -->
        <sso-select
          data-testid="select-geography"
          [label]="'Campaign.labels.parameters.geoId' | translate"
          [options]="geoOptions$ | async"
          [control]="baseForm?.controls?.displayedGeoId"
          [errorMessageMap]="errorMessages?.geoId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Company Entity -->
        <sso-select
          *ngIf="!isInputSimplified"
          data-testid="select-company"
          [label]="'Campaign.labels.parameters.companyId' | translate"
          [options]="companyOptions$ | async"
          [control]="baseForm?.controls?.companyId"
          [errorMessageMap]="errorMessages?.companyId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Sector -->
        <sso-select
          *ngIf="!isInputSimplified"
          data-testid="select-sector"
          [label]="'Campaign.labels.parameters.sectorId' | translate"
          [options]="sectorOptions$ | async"
          [control]="baseForm?.controls?.sectorId"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Process -->
        <sso-select
          data-testid="select-process"
          [label]="processLabel | translate"
          [options]="processOptions$ | async"
          [control]="baseForm?.controls?.processId"
          [required]="true"
          [errorMessageMap]="errorMessages?.processId"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Fluid -->
        <sso-select
          *ngIf="!isInputSimplified"
          data-testid="select-fluid"
          [label]="'Campaign.labels.parameters.fluidId' | translate"
          [options]="fluidOptions$ | async"
          [control]="baseForm?.controls?.fluidId"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Metric for scaling -->
        <sso-select
          *ngIf="!isInputSimplified"
          data-testid="select-metric"
          [label]="'Campaign.labels.parameters.metricId' | translate"
          [options]="metricOptions$ | async"
          [control]="baseForm?.controls?.metricId"
          [errorMessageMap]="errorMessages?.metricId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
      </div>
    </mat-expansion-panel>

    <!-- Main Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.main' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- Savings -->
        <sso-yearly-input
          data-testid="input-savings"
          formControlName="savings"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.savings'"
          [label]="'Campaign.labels.parameters.savings' | translate"
          [readonly]="readonly"
          [warning]="
            flagWarnings(baseForm?.controls?.savings?.value, startYear)
          "
          [warningMessage]="'Campaign.messages.zeroValueWarning' | translate"
        ></sso-yearly-input>
        <!-- Incremental Savings -->
        <sso-yearly-input
          data-testid="input-incrementalSavings"
          formControlName="incrementalSavings"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.incrementalSavings'"
          [label]="'Campaign.labels.parameters.incrementalSavings' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Technical life -->
        <sso-yearly-input
          data-testid="input-technicalLife"
          formControlName="technicalLife"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.technicalLife'"
          [label]="'Campaign.labels.parameters.technicalLife' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
        <!-- Implementation time -->
        <sso-yearly-input
          data-testid="input-implementationTime"
          formControlName="implementationTime"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.implementationTime'"
          [label]="'Campaign.labels.parameters.implementationTime' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>

        <!-- Depreciation time -->
        <sso-yearly-input
          data-testid="input-depreciationTime"
          formControlName="depreciationTime"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.depreciationTime'"
          [label]="'Campaign.labels.parameters.depreciationTime' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>

    <!-- Emission Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.emission' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- Build CO2 Scope 3 Emissions -->
        <sso-yearly-input
          data-testid="input-buildCo2Scope3Emissions"
          formControlName="buildCo2Scope3Emissions"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCo2Scope3Emissions'"
          [label]="
            'Campaign.labels.parameters.buildCo2Scope3Emissions' | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible CO2 Scope 3 Emissions -->
        <sso-yearly-input
          data-testid="input-indivisibleCo2Scope3Emissions"
          formControlName="indivisibleCo2Scope3Emissions"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="
            'Campaign.messages.errors.indivisibleCo2Scope3Emissions'
          "
          [label]="
            'Campaign.labels.parameters.indivisibleCo2Scope3Emissions'
              | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>

    <!-- Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.cost' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="20px">
        <!-- Build cost -->
        <sso-yearly-input
          data-testid="input-buildCost"
          formControlName="buildCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCost'"
          [label]="'Campaign.labels.parameters.buildCost' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible cost -->
        <sso-yearly-input
          data-testid="input-indivisibleCost"
          formControlName="indivisibleCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.indivisibleCost'"
          [label]="'Campaign.labels.parameters.indivisibleCost' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M -->
        <sso-yearly-input
          data-testid="input-foM"
          formControlName="foM"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foM'"
          [label]="'Campaign.labels.parameters.foM' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Incremental Build cost -->
        <sso-yearly-input
          data-testid="input-incrementalBuildCost"
          formControlName="incrementalBuildCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.incrementalBuildCost'"
          [label]="
            'Campaign.labels.parameters.incrementalBuildCost' | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Incremental Indivisible cost -->
        <sso-yearly-input
          data-testid="input-incrementalIndivisibleCost"
          formControlName="incrementalIndivisibleCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.incrementalIndivisibleCost'"
          [label]="
            'Campaign.labels.parameters.incrementalIndivisibleCost' | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M per install -->
        <sso-yearly-input
          data-testid="input-foMPerInstall"
          formControlName="foMPerInstall"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foMPerInstall'"
          [label]="'Campaign.labels.parameters.foMPerInstall' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
  </div>
  <button #submit type="submit" [hidden]="true"></button>
</form>

import { REFERENCES } from 'ssotool-app/app.references';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

import {
  BackendPortfolioCurve,
  BaseCurveEntity,
  ClientReferenceData,
  ResultReferenceCampaign,
  ResultReferenceEntities,
} from '../stores/result/result.model';

export type CurveValues = {
  [year: string]: number;
};

export class BaseGraphMapper {
  protected static BASE_COLOR = '#faca08';

  static zipYearsToValues = (years: string[], values) =>
    years.reduce((acc, year, index) => {
      acc[year] = values[index]?.toString();

      return acc;
    }, {});

  static mapEntitiesNameAndIndex = (
    data: BackendPortfolioCurve[],
    entities: ResultReferenceEntities,
    kpiType: string = '',
    years: any[],
    clientData: ClientReferenceData,
    campaignData: ResultReferenceCampaign,
    scope: string = '',
  ): BaseCurveEntity[] =>
    data.map((curr) => {
      return {
        lever: REFERENCES[curr.campaignType] || curr.campaignType,
        // remove geoId after ensuring core only returns siteId
        // and removing support for old clients (version<7.0)
        geography:
          entities?.site_mapping?.[curr.geoId] ||
          curr.geoId ||
          entities?.site_mapping?.[curr.siteId],
        site: entities?.site_mapping?.[curr.siteId],
        entity:
          entities?.company_entity_mapping?.[curr.companyEntityId] ||
          curr.companyEntityId,
        process: entities?.process_mapping?.[curr.processId] || curr.processId,
        fluid: entities?.fluid_mapping?.[curr.fluidId] || curr.fluidId,
        sector: entities?.sector_mapping?.[curr.sectorId] || curr.sectorId,
        // campaignName: campaignData?.[curr.campaignId]?.,
        campaignId: curr.campaignId,
        campaign: BaseGraphMapper.handleCampaignNames(
          curr.campaignId,
          curr.campaignType,
          curr.processId,
          // remove geoId after removing support for old clients (version<7.0)
          curr.siteId || curr.geoId,
          {
            ...entities?.existing_mapping,
            ...entities?.campaign_mapping,
            ...entities?.process_mapping,
            ...entities?.site_mapping,
          },
        ),
        campaignCategory: campaignData?.[curr.campaignId]?.category || '',
        geoHid:
          clientData?.geographies?.[
            curr.geoId || clientData?.sites?.[curr.siteId]?.['geoId']
          ]?.['hId'],
        geoType: clientData?.geographies?.[curr.geoId]?.['geoType'],
        companyHid: clientData?.companies?.[curr.companyEntityId]?.['hId'],
        kpiType,
        scope: scope ? scope : kpiType,
        values: BaseGraphMapper.zipYearsToValues(years, curr.values),
      };
    });

  static handleCampaignNames = (
    campaignId: string,
    campaignType: string,
    processId: string,
    geoId: string,
    mapper: Record<string, string>,
  ) => {
    if (campaignId?.startsWith('0000-0000-')) {
      if (processId && geoId) {
        return `${mapper?.[processId]}_${mapper?.[geoId]}`;
      } else {
        return campaignType;
      }
    } else {
      // Temporary handling of the campaign type None. Should be removed once core already implemented
      return campaignType !== 'None' ? mapper?.[campaignId] || '' : 'Market';
    }
  };

  static applySKtoPK = (data, primaryKey: string, secondaryKey: string) => {
    return data.map((curr) => ({
      ...curr,
      [primaryKey]: `${curr[primaryKey]}_${curr[secondaryKey]}`,
    }));
  };
}

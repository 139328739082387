import { CampaignFacadeService } from 'ssotool-app/+campaign/store';
import { ClientFacadeService } from 'ssotool-app/+client/store';
import {
  CAMPAIGN_REFERENCES_KEY,
  REFERENCES,
} from 'ssotool-app/app.references';
import { convertToYearlyValues, FormService } from 'ssotool-shared';

import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';

import { CampaignBaseFormComponent } from '../base/campaign-base-form.component';
import { getProcessLabelForCampaign } from '../campaign-drawer.utils';

@Component({
  selector: 'sso-drawer-storage',
  templateUrl: './storage-form.component.html',
  styleUrls: ['./storage-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageFormComponent extends CampaignBaseFormComponent {
  campaignType = REFERENCES.storage;
  defaultValues = {
    minCapacity: convertToYearlyValues('0.0', this.startYear, this.endYear),
    maxCapacity: convertToYearlyValues('0.0', this.startYear, this.endYear),
    epRatio: convertToYearlyValues('1.0', this.startYear, this.endYear),
    maxDod: convertToYearlyValues('1.0', this.startYear, this.endYear),
    roundtripEfficiency: convertToYearlyValues(
      '1.0',
      this.startYear,
      this.endYear,
    ),
    technicalLife: convertToYearlyValues('10', this.startYear, this.endYear),
    depreciationTime: convertToYearlyValues('10', this.startYear, this.endYear),
    implementationTime: convertToYearlyValues(
      '12',
      this.startYear,
      this.endYear,
    ),
    buildCo2Scope3Emissions1: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    buildCo2Scope3Emissions2: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    indivisibleCo2Scope3Emissions: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    buildCost1: convertToYearlyValues('0.0', this.startYear, this.endYear),
    buildCost2: convertToYearlyValues('0.0', this.startYear, this.endYear),
    indivisibleCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
    foM1: convertToYearlyValues('0.0', this.startYear, this.endYear),
    foM2: convertToYearlyValues('0.0', this.startYear, this.endYear),
    foMPerInstall: convertToYearlyValues('0.0', this.startYear, this.endYear),
  };
  baseForm = this._formBuilder.group({
    ...this.commonControls,
    campaignType: CAMPAIGN_REFERENCES_KEY.STORAGE,
    scale: ['', Validators.required],
    fluidId: ['', Validators.required],
    processId: ['', Validators.required],
    minCapacity: [this.defaultValues.minCapacity, Validators.required],
    maxCapacity: [this.defaultValues.maxCapacity, Validators.required],
    epRatio: [this.defaultValues.epRatio, Validators.required],
    maxDod: [this.defaultValues.maxDod, Validators.required],
    roundtripEfficiency: [
      this.defaultValues.roundtripEfficiency,
      Validators.required,
    ],
    technicalLife: [this.defaultValues.technicalLife, Validators.required],
    depreciationTime: [
      this.defaultValues.depreciationTime,
      Validators.required,
    ],
    implementationTime: [
      this.defaultValues.implementationTime,
      Validators.required,
    ],
    buildCo2Scope3Emissions1: [
      this.defaultValues.buildCo2Scope3Emissions1,
      Validators.required,
    ],
    buildCo2Scope3Emissions2: [
      this.defaultValues.buildCo2Scope3Emissions2,
      Validators.required,
    ],
    indivisibleCo2Scope3Emissions: [
      this.defaultValues.indivisibleCo2Scope3Emissions,
      Validators.required,
    ],
    buildCost1: [this.defaultValues.buildCost1, Validators.required],
    buildCost2: [this.defaultValues.buildCost2, Validators.required],
    indivisibleCost: [this.defaultValues.indivisibleCost, Validators.required],
    foM1: [this.defaultValues.foM1, Validators.required],
    foM2: [this.defaultValues.foM2, Validators.required],
    foMPerInstall: [this.defaultValues.foMPerInstall, Validators.required],
  });

  processLabel = getProcessLabelForCampaign();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _formBuilder: FormBuilder,
    formService: FormService,
    clientFacade: ClientFacadeService,
    campaignFacade: CampaignFacadeService,
  ) {
    super(ngControl, clientFacade, campaignFacade, formService);
  }
}

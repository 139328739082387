import { ClientDataEntity, Site } from 'ssotool-app/+client';
import { FilterWithCondition } from 'ssotool-shared';

export type DetailDataParameters = Readonly<{
  group: string;
  level: string;
  isCampaign: boolean;
  viewLevels: string[];
  filters: FilterWithCondition;
  requiredFields: string[];
  geos: ClientDataEntity;
  sites?: ClientDataEntity<Site>;
  geographyHierarchyMap: Record<string, string>;
  geographyNameLevelMap: Record<string, string>;
  geographyLevelNameMap: Record<string, string>;
}>;

export const GEOGRAPHY_VIEW = 'geography';
export const CAMPAIGN_VIEW = 'campaign';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EntityCommonTableModule } from 'ssotool-app/+entities/components/entity-common-table';
import { SitesListComponent } from './sites-list.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [SitesListComponent],
  imports: [BrowserModule, CommonModule, EntityCommonTableModule, RouterModule],
  exports: [SitesListComponent],
})
export class SitesListModule {}

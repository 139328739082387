import {
  BehaviorSubject,
  combineLatest,
  iif,
  Observable,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { RoadmapIoService } from 'ssotool-app/+roadmap/modules/io/roadmap-io.service';
import { MAIN_TABS } from 'ssotool-app/+roadmap/services/roadmap-filter-storage';
import { RoadmapFiltersStorage } from 'ssotool-app/+roadmap/services/roadmap-filter-storage/roadmap-filter-storage.service';
import {
  HierarchicalFilter,
  NewKPIType,
  ResultFilterOptions,
  RoadmapVisualization,
  RoadmapVisualizationFacadeService,
  VisualizationMap,
} from 'ssotool-app/+roadmap/stores';
import { ChartFacadeService } from 'ssotool-app/+roadmap/stores/charts/charts.facade.service';
import { ResultFiltersService } from 'ssotool-app/+roadmap/utilities';
import {
  ELLIPSE_CURVE_COLORS,
  ENGIE_CURVE_COLORS,
} from 'ssotool-app/app.references';
import { fadeInAnimation } from 'ssotool-core/animations';
import {
  Coerce,
  FormFieldOption,
  getEnumKeyByValue,
} from 'ssotool-shared/helpers';
import {
  ColorPickerComponent,
  FilterDialogForm,
  FilteredComponent,
  GROUP_INDICATOR,
} from 'ssotool-shared/modules';

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ChartInterpretation,
  ChartType,
  GroupedOption,
} from './kpi-curve.models';
import {
  CAMPAIGN,
  COMPLEMENTARY_FILTER_FIELDS,
  DISPLAY_LEVEL_FIELD,
  GEOGRAPHY,
  SANKEY_KPIS,
  SITE,
  STACKED_BARS,
  YEARS,
  ZERO_VALUES_CURVE,
} from './kpi-curve.references';
import { KPICurveInterpreter } from './kpi-curve.service';
import { ChartDetails } from 'ssotool-app/+roadmap/services/curve-export/kpi-curve-export.model';
import { RoadmapVariationFacadeService } from 'ssotool-app/+roadmap/stores/roadmap-variation';
import { ColorMap } from 'ssotool-app/+roadmap/stores/visualization/visualization.model';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
@UntilDestroy()
@Component({
  selector: 'sso-kpi-curve-v2',
  templateUrl: './kpi-curve.component.html',
  styleUrls: ['./kpi-curve.component.scss'],
  animations: [fadeInAnimation],
  providers: [ChartFacadeService],
})
export class KpiCurveComponent
  extends FilteredComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  @Input() kpiOptions: FormFieldOption<string>[] = [];
  @Input() curveOptions: FormFieldOption<string>[] = [];
  @Input() subKpiOptions: FormFieldOption<string>[] = [];
  @Input() activeFilters: string[] = [];
  @Input() indicatorField: string = null;
  @Input() curveName: string = '';

  private _variationId = new BehaviorSubject<string>('');
  @Input() set variationId(id: string) {
    this._variationId.next(id);
  }
  get variationId() {
    return this._variationId.value;
  }

  private _roadmapId = new BehaviorSubject<string>('');
  @Input() set roadmapId(id: string) {
    this._roadmapId.next(id);
  }
  get roadmapId() {
    return this._roadmapId.value;
  }

  labelId = '';

  groupVisibility = new BehaviorSubject<'hidden' | ''>('hidden');
  levelVisibility = new BehaviorSubject<'hidden' | ''>('hidden');

  selectedKPI$: Observable<ChartInterpretation>;
  isFavorite$ = new BehaviorSubject<RoadmapVisualization>(null);
  levelOptions: Observable<Record<string, FormFieldOption<string>[]>>;
  private showZeroValues = new BehaviorSubject<boolean>(true);
  showZeroValues$ = this.showZeroValues.asObservable();

  private isZeroValueToggleDisplayed = new BehaviorSubject<boolean>(false);
  isZeroValueToggleDisplayed$ = this.isZeroValueToggleDisplayed.asObservable();

  private _sankeyGeographyOptions = new BehaviorSubject<
    FormFieldOption<string>[]
  >([]);
  sankeyGeographyOptions$ = this._sankeyGeographyOptions.asObservable();
  get sankeyGeographyOptions() {
    return this._sankeyGeographyOptions.value;
  }
  private _sankeyYearOptions = new BehaviorSubject<FormFieldOption<string>[]>(
    [],
  );
  sankeyYearOptions$ = this._sankeyYearOptions.asObservable();
  get sankeyYearOptions() {
    return this._sankeyYearOptions.value;
  }

  private defaultFormValue = {
    kpi: '',
    subKpi: [],
    curveType: '',
    splitBy: { group: CAMPAIGN.value, level: '' },
    enumerateBy: { group: YEARS.value, level: '' },
    filters: {},
    indicatorField: '',
    geography: '',
    year: '',
    variationId: '',
    showZeroValue: true,
  };
  form = this.formBuilder.group({
    kpi: [this.defaultFormValue.kpi, Validators.required],
    subKpi: this.defaultFormValue.subKpi,
    curveType: [this.defaultFormValue.curveType, Validators.required],
    splitBy: this.defaultFormValue.splitBy,
    enumerateBy: this.defaultFormValue.enumerateBy,
    filters: this.defaultFormValue.filters,
    indicatorField: this.defaultFormValue.indicatorField,
    geography: this.defaultFormValue.geography,
    year: this.defaultFormValue.year,
    variationId: this.defaultFormValue.variationId,
    showZeroValue: this.defaultFormValue.showZeroValue,
  });
  chipForm = new FormControl({});
  stripLabel = GROUP_INDICATOR;

  filterOptions$: Observable<ResultFilterOptions>;
  filtersDialogConfig = this.filtersService.dialogConfig;
  filterVisibility = false;

  private _clientId = this._activeRoute.parent.params.pipe(
    map((data) => data.clientId),
    untilDestroyed(this),
  );
  clientId$ = new BehaviorSubject<string>('');
  private _canSave = new BehaviorSubject<boolean>(true);
  canSave$ = this._canSave.asObservable();

  private _xAxisWithLevelOptions = new BehaviorSubject<
    FormFieldOption<GroupedOption>[]
  >([]);

  private _splitByWithLevelOptions = new BehaviorSubject<
    FormFieldOption<GroupedOption>[]
  >([]);

  splitByWithLevelOptions$: Observable<FormFieldOption<GroupedOption>[]> =
    combineLatest([
      this.form.controls.enumerateBy.valueChanges.pipe(startWith({})),
      this._splitByWithLevelOptions,
    ]).pipe(
      map(([enumerateBy, options]) =>
        options.filter(
          (op) => op.value.group != (enumerateBy as GroupedOption)?.group,
        ),
      ),
    );

  xAxisWithLevelOptions$: Observable<FormFieldOption<GroupedOption>[]> =
    this._xAxisWithLevelOptions.asObservable();

  @Input() set groupWithLevelOptions(
    groupWithLevelOptions: FormFieldOption<GroupedOption>[],
  ) {
    this._xAxisWithLevelOptions.next(groupWithLevelOptions);
    this._splitByWithLevelOptions.next(groupWithLevelOptions);
  }

  private _xAxisOptions = new BehaviorSubject<FormFieldOption<GroupedOption>[]>(
    [],
  );

  private _splitByOptions = new BehaviorSubject<
    FormFieldOption<GroupedOption>[]
  >([]);

  splitByOptions$: Observable<FormFieldOption<GroupedOption>[]>;

  xAxisOptions$: Observable<FormFieldOption<GroupedOption>[]> =
    this._xAxisOptions.asObservable();

  hideXAxis$ = this.form.controls.curveType.valueChanges.pipe(
    map((curveType) => !STACKED_BARS.includes(curveType)),
  );

  private _groupOptions = new BehaviorSubject<FormFieldOption<any>[]>([]);

  @Input() set groupOptions(groupOptions: FormFieldOption<any>[]) {
    if (!this.isInputSimplified) {
      this._xAxisOptions.next(groupOptions);
      this._splitByOptions.next(groupOptions);
    }
    this._groupOptions.next(groupOptions);
  }

  @Input() set groupOptionsV7(groupOptionsV7: FormFieldOption<any>[]) {
    if (this.isInputSimplified) {
      this._xAxisOptions.next(groupOptionsV7);
      this._splitByOptions.next(groupOptionsV7);
    }
  }

  get groupOptions() {
    return this._groupOptions.getValue();
  }

  splitDisplay$ = this.form.controls.splitBy.valueChanges.pipe(
    startWith({ group: this.groupOptions[1]?.value, level: '' }),
    mergeMap((value) =>
      iif(
        () => !!value?.level,
        this.levelOptions.pipe(
          map(
            (op) =>
              op[value.group].find((el) => el.value === value.level)?.name,
          ),
        ),
        this._groupOptions.pipe(
          map((op) => op.find((el) => el.value === value.group)?.name),
        ),
      ),
    ),
  );

  get isSankey() {
    return this.form.controls.curveType.value === ChartType.SANKEY;
  }

  get filterControl() {
    return this.form.controls.filters as FormControl;
  }

  get kpiControl() {
    return this.form.controls.kpi as FormControl;
  }

  get isInputSimplified(): boolean {
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE);
  }

  private _colorMap: ColorMap = {};
  private _vizMap: BehaviorSubject<VisualizationMap> =
    new BehaviorSubject<VisualizationMap>(this.generateVizMap());
  private _fromVisualizationPage: boolean = false;

  private colorSet = [];

  constructor(
    private interpreter: KPICurveInterpreter,
    private formBuilder: FormBuilder,
    private chartFacadeService: ChartFacadeService,
    private colorPickerDialog: MatDialog,
    private filtersService: ResultFiltersService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private filtersStorage: RoadmapFiltersStorage,
    private visualizationFacade: RoadmapVisualizationFacadeService,
    private ioService: RoadmapIoService,
    private variationFacade: RoadmapVariationFacadeService,
  ) {
    super(filtersService);
  }

  ngOnInit(): void {
    this.initializeQueryParamInsertion();
    this.initializeSankeyOptions();
    this.initializeForm();
    this.initializeFiltersAndLevel();
    this.initializeFilterStorage();

    this.selectedKPI$ = this._variationId.pipe(
      mergeMap((variationId) =>
        this.interpreter.getCurveToDisplay({
          ...this.form.value,
          variationId,
          kpi: this.form.value.kpi,
        }),
      ),
    );

    this._clientId.subscribe((clientId) => {
      this.clientId$.next(clientId);
    });
  }

  ngOnDestroy(): void {
    this.visualizationFacade.resetActiveVisualization();
    this.chartFacadeService.resetColorMap();
    this.filtersStorage.clear();
  }

  ngAfterViewInit(): void {
    this.patchValuesFromQueryParams();

    this._variationId
      .pipe(untilDestroyed(this), tap(this.patchValuesFromQueryParams))
      .subscribe((variationId) => {
        this.selectedKPI$ = this.interpreter.getCurveToDisplay({
          ...this.form.value,
          variationId,
          kpi: this.form.value.kpi,
        });
      });
  }

  ngAfterContentInit(): void {
    this.initializeFormChangesSubscription();
    this.getRoadmapVisualizationNav();

    // Trigger initial change
    if (!this._fromVisualizationPage) {
      this.form.updateValueAndValidity({ emitEvent: true });
    }
  }

  getRoadmapVisualizationNav(): void {
    let mappedData = {
      enumerateBy: {
        group: '',
        level: '',
      },
      variationId: '',
      splitBy: { group: '', level: '' },
    };
    this.visualizationFacade.activeVisualization$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((roadmapVisualization) => {
        if (roadmapVisualization) {
          this._fromVisualizationPage = true;
          const { details } = roadmapVisualization;

          if (details.colorMap && Object.keys(details.colorMap).length > 0) {
            Object.entries(details.colorMap).forEach(([label, hexValue]) => {
              this.chartFacadeService.setColorByLabel(label, hexValue);
            });
          }

          mappedData = {
            ...details,
            enumerateBy: {
              group: '',
              level: '',
            },
            variationId: this.variationId,
          };

          if (details.xAxis && Object.keys(details.xAxis).length > 0) {
            mappedData.enumerateBy = {
              group: details.xAxis.group,
              level: details.xAxis.level,
            };
          } else {
            mappedData.enumerateBy = this.setEnumerateBy(mappedData);
          }

          this.form.patchValue(mappedData);
        }
      });

    this.setSplitOptions(this._fromVisualizationPage, mappedData);
    this.ioService.setRoadmapVisualizationNav(null);
    this._fromVisualizationPage = false;
  }

  private setSplitOptions(fromVisualizationPage, mappedData) {
    const initEnumerate = fromVisualizationPage
      ? this.setEnumerateBy(mappedData)
      : { group: 'years', level: '' };

    this.splitByOptions$ = combineLatest([
      this.form.controls.enumerateBy.valueChanges.pipe(
        startWith(initEnumerate),
      ),
      this._splitByOptions,
    ]).pipe(
      map(([enumerateBy, options]) => {
        return options.filter((op) => op.value != enumerateBy?.group);
      }),
    );
  }

  private setEnumerateBy(mappedData) {
    let groupIndex = this.groupOptions.findIndex((option) => {
      return option.value === mappedData.splitBy.group;
    });

    if (groupIndex === -1) {
      groupIndex = 1;
    } else if (
      groupIndex === 0 ||
      groupIndex !== this.groupOptions.length - 1
    ) {
      groupIndex += 1;
    } else {
      groupIndex -= 1;
    }

    return {
      group: this.groupOptions[groupIndex].value,
      level: '',
    };
  }

  private initializeFilterStorage(): void {
    this.hookRoadmapToInitialFilters();
    this.subscribeFilterStorageToFilterChanges();
  }

  private hookRoadmapToInitialFilters(): Subscription {
    return this._roadmapId
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((id: string) => {
        const existingFilters = this.filtersStorage.get(
          this.roadmapId,
          MAIN_TABS.PORTFOLIO_ANALYSIS,
        );
        if (existingFilters?.[this.curveName]) {
          this.filterControl.patchValue(existingFilters[this.curveName]);
        }
      });
  }

  private subscribeFilterStorageToFilterChanges(): Subscription {
    return this.filterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((changes) => {
        this.filtersStorage.set(
          this.roadmapId,
          MAIN_TABS.PORTFOLIO_ANALYSIS,
          this.curveName,
          changes,
        );
      });
  }

  private initializeSankeyOptions() {
    combineLatest([
      this.interpreter.getSankeyGeographyOptions(this.variationId),
      this.interpreter.getSankeyYearOptions(this.variationId),
      this._variationId,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([geos, years, _varId]) => {
        this._sankeyGeographyOptions.next(geos);
        this._sankeyYearOptions.next(years);
      });
  }

  private initializeForm() {
    this.initializeVizMapSubscription();
    this.initializeColorMapChangesSubscription();
    this.initializeEnumerateBySubscription();
    this.initializeCurveTypeSubscription();
    this.initializeFormValues();
    this.initializeKpiSubscription();
  }

  private initializeVizMapSubscription() {
    this._vizMap
      .pipe(
        untilDestroyed(this),
        filter((viz) => viz.kpi.length > 0),
        switchMap((viz) => {
          return this.visualizationFacade.getExistingFromDetails(viz);
        }),
      )
      .subscribe((record) => {
        this.isFavorite$.next(record);
      });
  }

  private initializeColorMapChangesSubscription() {
    this.chartFacadeService
      .getLabelColorData()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this._colorMap = value;
      });
  }

  private initializeFormChangesSubscription(): void {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((changes) => {
          this.onFormChange(changes);
        }),
        switchMap(() => {
          const vizMap = this.generateVizMap();

          // To handle previous saved viz. ColorMap is not existing before.
          // diff will not be reliable
          if (!this._colorMap || Object.keys(this._colorMap).length === 0) {
            delete vizMap.colorMap;
          }

          return this.visualizationFacade.getExistingFromDetails(vizMap);
        }),
        tap((record) => this.isFavorite$.next(record)),
        distinctUntilChanged(),
        tap(() => {
          if (this._fromVisualizationPage) {
            this._fromVisualizationPage = false;
          }
        }),
      )
      .subscribe();
  }

  private onFormChange(changes) {
    this.labelId = changes.kpi;
    this.selectedKPI$ = this.interpreter.getCurveToDisplay(changes);
  }

  toggleVisualization(): void {
    const { value: clientId } = this.clientId$;
    let details = this.generateVizMap();

    // To handle previous saved viz. ColorMap is not existing before.
    // diff will not be reliable
    if (!this._colorMap || Object.keys(this._colorMap).length === 0) {
      delete details.colorMap;
    }

    const favorite = this.isFavorite$.value;

    if (!favorite || Object.keys(favorite).length === 0) {
      this.chartFacadeService.setColorByLabelGrouped(this.colorSet);
      details = this.generateVizMap();
      this._vizMap.next(details);
      this.saveVisualization(clientId, this.roadmapId, details);
    } else {
      this.deleteVisualization(favorite);
    }
  }

  private saveVisualization(
    clientId: string,
    roadmapId: string,
    details: VisualizationMap,
  ) {
    this.visualizationFacade.save(clientId, roadmapId, details);
  }

  private deleteVisualization(roadmapVisualization: RoadmapVisualization) {
    this.visualizationFacade.delete(roadmapVisualization);
  }

  generateVizMap(): VisualizationMap {
    let formControls = this.form.controls;
    return {
      curveType: formControls.curveType.value,
      filters: formControls.filters.value || {},
      geography: formControls.geography.value,
      indicatorField: formControls.indicatorField.value,
      kpi: formControls.kpi.value,
      kpiType: NewKPIType[getEnumKeyByValue(NewKPIType, this.curveName)],
      showZeroValue: formControls.showZeroValue.value,
      splitBy: formControls.splitBy.value,
      subKpi: formControls.subKpi.value,
      xAxis: STACKED_BARS.includes(formControls.curveType.value)
        ? formControls.enumerateBy.value
        : ({} as GroupedOption),
      year: formControls.year.value,
      colorMap: this._colorMap || {},
    };
  }

  private initializeEnumerateBySubscription() {
    this.form.controls.enumerateBy.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((enumerateBy) => {
        const splitByValue = this.form.controls.splitBy.value;
        if (
          COMPLEMENTARY_FILTER_FIELDS[splitByValue.level] === enumerateBy?.group
        ) {
          return;
        }
        if (
          enumerateBy?.group === splitByValue.group ||
          ([enumerateBy?.level, enumerateBy?.group].includes(SITE.value) &&
            splitByValue.group === GEOGRAPHY.value)
        ) {
          const firstOption = this.groupOptions[0]?.value;
          this.form.controls.splitBy.patchValue({
            group:
              firstOption === enumerateBy?.group
                ? this.groupOptions[1]?.value
                : firstOption,
            level: '',
          });
        }
      });

    this.form.controls.splitBy.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((splitBy) => {
        const enumerateByValue = this.form.controls.enumerateBy.value.group;
        if (COMPLEMENTARY_FILTER_FIELDS[enumerateByValue] === splitBy?.level) {
          return;
        }
        if (splitBy?.group === enumerateByValue) {
          const firstOption = this.groupOptions[0]?.value;
          this.form.controls.enumerateBy.patchValue({
            group:
              firstOption === splitBy?.group
                ? this.groupOptions[1]?.value
                : firstOption,
            level: '',
          });
        }
      });
  }

  private initializeCurveTypeSubscription() {
    this.form.controls.curveType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((curveType) => {
        if (STACKED_BARS.includes(curveType)) {
          this.form.controls.enumerateBy.patchValue({
            group: 'years',
            level: '',
          });
        } else {
          this.form.controls.enumerateBy.patchValue({} as GroupedOption);
        }
        this.checkIfDisplayZeroValueToggle(curveType as ChartType);
      });
  }

  private initializeFormValues() {
    this.form.patchValue({
      kpi: this.kpiOptions[0]?.value,
      curveType: this.curveOptions[0]?.value,
      enumerateBy: { group: YEARS.value, level: '' },
      splitBy: { group: CAMPAIGN.value, level: '' },
      subKpi: this.setDefaultSubKpi(),
      filters: {},
      indicatorField: this.indicatorField,
      variationId: this.variationId,
      showZeroValue: this.showZeroValues.value,
    });
  }

  private initializeKpiSubscription() {
    this.form.controls.kpi.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((kpi) => {
        if (SANKEY_KPIS.includes(kpi)) {
          this.form.controls.curveType.patchValue(ChartType.SANKEY);
          if (this.sankeyGeographyOptions.length) {
            if (
              !this.form.controls.geography ||
              this.form.controls.geography.value === ''
            ) {
              this.form.controls.geography.patchValue(
                this.sankeyGeographyOptions[0].value,
              );
            }
          }

          if (this.sankeyYearOptions.length) {
            this.form.controls.year.patchValue(this.sankeyYearOptions[0].value);
          }
        } else {
          if (
            !this.form.controls.curveType ||
            this.form.controls.curveType.value === 'sankey' ||
            this.form.controls.curveType.value === ''
          ) {
            this.form.controls.curveType.patchValue(
              this.curveOptions[0]?.value,
            );
          }
        }
      });
  }

  protected initializeFiltersAndLevel(): void {
    this.filterOptions$ = this.interpreter.getFilterOptions(
      this.activeFilters,
      this.variationId,
    );
    this.filterSubscriptions.add(
      this.filtersService.initializeFilterControls(
        this.filterControl,
        [],
        [this.chipForm],
      ),
    );
    this.initializeLevelOptions();
  }

  private initializeLevelOptions() {
    this.levelOptions = this.interpreter.getLevelOptions(DISPLAY_LEVEL_FIELD);
  }

  private initializeQueryParamInsertion() {
    this.getValueChangesForQuery()
      .pipe(untilDestroyed(this))
      .subscribe(([kpi, enumerateBy, splitBy, year, geography]) => {
        this.addToQueryParams({
          kpi,
          xAxisGroup: enumerateBy?.group,
          xAxisLevel: enumerateBy?.level,
          splitByGroup: splitBy?.group,
          splitByLevel: splitBy?.level,
          year: this.isSankey ? year : null,
          geography: this.isSankey ? geography : null,
        });
      });
  }

  private getValueChangesForQuery(): Observable<
    [string, GroupedOption, GroupedOption, string, string]
  > {
    return combineLatest([
      this.form.controls.kpi.valueChanges.pipe(
        startWith(this.defaultFormValue.kpi),
      ),
      this.form.controls.enumerateBy.valueChanges.pipe(
        startWith(this.defaultFormValue.enumerateBy),
      ),
      this.form.controls.splitBy.valueChanges.pipe(
        startWith(this.defaultFormValue.splitBy),
      ),
      this.form.controls.year.valueChanges.pipe(
        startWith(this.defaultFormValue.year),
      ),
      this.form.controls.geography.valueChanges.pipe(
        startWith(this.defaultFormValue.geography),
      ),
    ]);
  }

  private addToQueryParams(data: Object): void {
    this._router.navigate([], {
      relativeTo: this._activeRoute,
      queryParams: {
        ...this._activeRoute?.snapshot?.queryParams,
        ...data,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
  }

  private patchValuesFromQueryParams(): void {
    const params = this._activeRoute?.snapshot?.queryParams;

    const formObject = {};
    Object.entries(params || {}).forEach(([key, value]) => {
      switch (key) {
        case 'kpi':
          formObject['kpi'] = value;
          break;
        case 'xAxisGroup':
          formObject['enumerateBy'] = {
            group: value,
            level: params?.['xAxisLevel'] || '',
          };
          break;
        case 'splitByGroup':
          formObject['splitBy'] = {
            group: value,
            level: params?.['splitByLevel'] || '',
          };
          break;
        case 'year':
          formObject['year'] = value;
          break;
        case 'geography':
          formObject['geography'] = value;
        default:
          break;
      }
    });

    if (Coerce.getObjKeys(formObject)?.length) {
      this.form.patchValue(formObject);
    }
  }

  checkIfDisplayZeroValueToggle(curveType: ChartType) {
    if (ZERO_VALUES_CURVE.includes(curveType)) {
      this.isZeroValueToggleDisplayed.next(true);
    } else {
      this.isZeroValueToggleDisplayed.next(false);
    }
  }

  private setDefaultSubKpi() {
    return this.subKpiOptions?.map((option) => option.value) || [];
  }

  compareFn(opt1: HierarchicalFilter, opt2: HierarchicalFilter): boolean {
    return opt1.group === opt2.group && opt1.level === opt2.level;
  }

  toggleFilterVisibility() {
    this.filterVisibility = !this.filterVisibility;
  }

  displayZeroValues() {
    this.showZeroValues.next(this.form.controls.showZeroValue.value);
  }

  legendClick(legendItem) {
    this.colorPickerDialog
      .open(ColorPickerComponent, {
        data: {
          name: legendItem.name,
          color: legendItem.color,
          presets: ELLIPSE_CURVE_COLORS.concat(ENGIE_CURVE_COLORS),
        },
      })
      .afterClosed()
      .subscribe((data: ColorMap) => {
        if (data) {
          this.chartFacadeService.setColorByLabelGrouped(this.colorSet);
          this.chartFacadeService.setColorByLabel(data.name, data.color);
          this._vizMap.next(this.generateVizMap());
        }
      });
  }

  private chartJSDataChanges(data) {
    this.colorSet = [];
    if (data.name === 'stacked-bar') {
      data.datasets.forEach((item) => {
        if (item.backgroundColor) {
          this.colorSet.push({
            hexCode: item.backgroundColor.slice(0, 7),
            label: item.label,
          });
        }
      });
    } else {
      data.datasets.reduce((acc, dataset) => {
        dataset.backgroundColor.forEach((color, i) => {
          if (color) {
            this.colorSet.push({
              hexCode: color.slice(0, 7),
              label: data.labels[i],
            });
          }
        });
        return acc;
      }, []);
    }
  }

  export(): void {
    combineLatest([this.selectedKPI$, this.getSlideTitle()])
      .pipe(take(1))
      .subscribe(([chart, title]) => {
        this.interpreter.export(chart, this.createExportDetails(title));
      });
  }

  private createExportDetails(title: string): ChartDetails {
    return {
      title,
      kpi: this.form.controls.kpi.value,
      curveType: this.form.controls.curveType.value as ChartType,
      showZeroValues: this.showZeroValues.value,
    };
  }

  private getSlideTitle(): Observable<string> {
    let roadmapId = this._roadmapId.value;
    let variationId = this._variationId.value;

    return combineLatest([
      this.ioService.roadmap$,
      this.variationFacade.variation$(roadmapId, variationId),
    ]).pipe(
      map(([roadmap, variation]) => {
        return `${roadmap.name}: ${variation.name}`;
      }),
    );
  }
}

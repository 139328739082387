import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENTITY_KEY, GEOGRAPHY_KEY } from 'ssotool-app/+client';
import {
  BaseCurveEntity,
  CurveData,
  PortfolioCurveEntities,
  PortfolioCurveEntitiesType,
  PortfolioCurveEntity,
  ProjectDetails,
  ResultFilterOptions,
} from 'ssotool-app/+roadmap/stores/result/result.model';
import { ClientFiltersService } from 'ssotool-client/services';
import {
  Coerce,
  FilterConditionValue,
  FiltersDialogConditionMode,
  FiltersDialogConfiguration,
  FilterWithCondition,
  FilterWithConditionData,
} from 'ssotool-shared';

import { Injectable } from '@angular/core';

import { DetailDataParameters } from './result-utility.model';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

@Injectable()
export class ResultFiltersService extends ClientFiltersService {
  dialogConfig: FiltersDialogConfiguration = {
    conditionMode: {
      [GEOGRAPHY_KEY]: FiltersDialogConditionMode.WITH_GROUP,
      [ENTITY_KEY]: FiltersDialogConditionMode.WITH_GROUP,
    },
  };
  filterSettings = {
    keyMapper: {
      company: 'entity',
    },
  };

  get isInputSimplified(): boolean {
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE);
  }

  /**
   * Filters the projects based on the provided params.
   * If the input simplification feature is enabled, it will add the geography in the project.
   * @param clientId The id of the client
   * @param projects The projects to filter
   * @param params The parameters to filter the projects
   * @returns An observable of filtered projects
   */
  filterDetailedProjects(
    clientId: string,
    projects: ProjectDetails[],
    params: DetailDataParameters,
  ): Observable<ProjectDetails[]> {
    // Add geography in project
    if (this.isInputSimplified) {
      projects.forEach((project) => {
        const geoParent = Object.values(params.sites).find(
          (site) => site.name === project.site,
        )?.geoName;
        project.geography = geoParent || null;
      });
    }

    return this.getResultFilterControlFunction<ProjectDetails>(
      clientId,
      projects,
      this.getProjectFilters(params),
    ).pipe(map(({ data }) => data));
  }

  /**
   * Given the detail parameters, returns the filters for projects.
   * @param {DetailDataParameters} params The detail parameters.
   * @returns {FilterWithCondition} The filters for projects.
   */
  private getProjectFilters({
    filters,
    isCampaign,
    viewLevels,
    sites,
  }: DetailDataParameters) {
    const siteName = isCampaign ? viewLevels[2] : viewLevels[1];
    const isSite = this.isInputSimplified
      ? Object.values(sites).some((site) => site.name === siteName)
      : false;
    const isWorldLevel = viewLevels[1] === 'World';

    const geoFilter = this.isInputSimplified
      ? isSite
        ? {
            site: {
              values: [siteName],
              condition: FilterConditionValue.IS,
            },
          }
        : isWorldLevel
        ? {}
        : {
            geography: {
              values: [siteName],
              condition: FilterConditionValue.IS_PART_OF,
            },
          }
      : {
          geography: {
            values: [siteName],
            condition: FilterConditionValue.IS,
          },
        };

    return {
      ...filters,
      campaign: {
        values: [isCampaign ? viewLevels[1] : viewLevels[2]],
        condition: FilterConditionValue.IS,
      },
      ...geoFilter,
    };
  }

  filterDetailedCurves(
    clientId: string,
    curve: PortfolioCurveEntities,
    params: DetailDataParameters,
  ): Observable<CurveData> {
    return combineLatest(
      this.getDetailedCurveFilterControlFunction(clientId, curve, params),
    ).pipe(
      map((data) =>
        data.reduce((acc, [key, entities]) => {
          acc[key] = entities;
          return acc;
        }, {}),
      ),
    );
  }

  private getDetailedCurveFilterControlFunction(
    clientId: string,
    curve: PortfolioCurveEntities,
    { filters, requiredFields }: DetailDataParameters,
  ): Observable<[string, BaseCurveEntity[]]>[] {
    return Coerce.getObjValues(curve).reduce(
      (acc, kpiCurveData: PortfolioCurveEntitiesType) => {
        Object.entries(kpiCurveData).forEach(([kpi, kpiData]) => {
          if (requiredFields.includes(kpi)) {
            acc.push(
              this.filterPortfolioCurve(clientId, kpiData, filters).pipe(
                map(({ data }) => [kpi, data]),
              ),
            );
          }
        });
        return acc;
      },
      [],
    );
  }

  filterPortfolioCurve(
    clientId: string,
    entity: PortfolioCurveEntity,
    filters: FilterWithCondition,
  ): Observable<PortfolioCurveEntity> {
    return this.getResultFilterControlFunction<BaseCurveEntity>(
      clientId,
      entity.data,
      filters,
    ).pipe(
      map(({ data }) => ({
        data,
        unit: entity.unit,
      })),
    );
  }

  getResultFilterOptions(
    clientId: string,
    otherFilters: ResultFilterOptions,
    activeFilters: string[],
  ) {
    return this.getFilterOptions(clientId, [], this.filterSettings).pipe(
      map((clientOptions) => ({
        ...activeFilters.reduce((acc, filter) => {
          acc[filter] =
            filter === 'site' ? otherFilters.geography : otherFilters?.[filter];
          return acc;
        }, {}),
        ...clientOptions,
      })),
    );
  }

  private getResultFilterControlFunction<T>(
    clientId: string,
    data: T[],
    filters: FilterWithCondition,
  ) {
    const filterCopy = { ...filters };
    let yearFilters = filterCopy?.years;

    if (yearFilters) {
      delete filterCopy.years;
    }

    return this.getFilterControlFunction<T>(
      clientId,
      data,
      filterCopy,
      null,
      this.filterSettings,
    ).pipe(
      map(({ data, ids }) => ({
        data: yearFilters ? this.filterYears<T>(data || [], yearFilters) : data,
        ids,
      })),
    );
  }

  private filterYears<T>(data: T[], filter: FilterWithConditionData): T[] {
    return data.map((datum) => ({
      ...datum,
      values: this.getFilteredYears(datum?.['values'], filter),
    }));
  }

  private getFilteredYears(
    values: Record<string, string>,
    filter: FilterWithConditionData,
  ) {
    if (!filter.values?.length) {
      return values;
    }

    return Object.entries(values || {})
      .filter(([year, _]) => this.checkFilterCondition('', [year], filter))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }
}

import { combineLatest, Observable } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Coerce } from 'ssotool-app/shared/helpers';
import {
  generateKeyNameReference,
  getLeavesFromHids,
} from 'ssotool-shared/helpers/form-helpers';
import { distinctObject } from 'ssotool-shared/helpers/reactive-operator';
import { GROUP_INDICATOR } from 'ssotool-shared/modules/filters';
import { localStorage } from 'ssotool-shared/services/local-storage.service';

import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import {
  COMPANY_GROUPS_KEY,
  COMPANY_KEY,
  ENTITY_KEY,
  GEO_GROUPS_KEY,
  GEOGRAPHY_KEY,
} from '../client.const';
import { ClientActions } from './client.actions';
import {
  Client,
  ClientDataEntity,
  ClientShareInfo,
  ClientStatus,
  ClientTreeNode,
  Geography,
  GroupedFilterNames,
  Groups,
  GroupType,
  GroupUnitEntity,
  Hierarchy,
  HierarchyFilterRecord,
  RunSettings,
  Site,
} from './client.model';
import { ClientState } from './client.reducer';
import {
  activeClientId,
  archivedClients,
  clientList,
  clientListEntities,
  errorInSandbox,
  getActiveClientData,
  hasClients,
  loaded,
  loading,
  selectArchetypes,
  selectClient,
  selectClientFilterOptions,
  selectClientList,
  selectClientLoaded,
  selectClientLoading,
  selectClientPermission,
  selectCompanies,
  selectCompanyFlatMemberNames,
  selectCompanyHierarchy,
  selectConstraints,
  selectConverters,
  selectDataLoaded,
  selectDataLoading,
  selectExistingAssets,
  selectExistingContracts,
  selectFilteredClients,
  selectFlowPrices,
  selectFlowVolumes,
  selectFluids,
  selectGeoFlatMemberNames,
  selectGeographyGroups,
  selectGeographyTree,
  selectGeoHierarchy,
  selectGeos,
  selectGeosAndSites,
  selectGroups,
  selectIneligibleCampaignsForComputation,
  selectIsIneligibleForComputation,
  selectMetrics,
  selectProcesses,
  selectQuantities,
  selectRenewables,
  selectRootGeography,
  selectRunSettings,
  selectScalingFactors,
  selectSectors,
  selectShareDataLoading,
  selectSites,
  selectStorages,
  selectTimeSeries,
  selectTrajectories,
  selectTrajectoryVariations,
  sharing,
  uniqueOwnerIds,
  selectGeoHierarchyWOSites,
} from './client.selector';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

const ACTIVE_CLIENT_ID = 'activeClientId';

@Injectable()
export class ClientFacadeService {
  constructor(
    private store: Store<ClientState>,
    private actionSubject$: ActionsSubject,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  getClientEntities$ = this.store.pipe(select(clientListEntities));
  getClientList$ = this.store.pipe(select(clientList));
  hasClients$ = this.store.pipe(select(hasClients));
  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  errorInSandbox$ = this.store.pipe(select(errorInSandbox));
  sharing$ = this.store.pipe(select(sharing));
  activeClientId$ = this.store.pipe(select(activeClientId));
  selectActiveClientData$ = this.store.pipe(select(getActiveClientData));
  selectArchiveClients$ = this.store.pipe(select(archivedClients));
  uniqueOwnerIds$ = this.store.pipe(select(uniqueOwnerIds));
  selectClientLoaded$ = (clientId: string) =>
    this.store.select(selectClientLoaded(clientId));
  selectClientPermission$ = (clientId: string) =>
    this.store.select(selectClientPermission(clientId));
  selectClient$ = (clientId: string) =>
    this.store.select(selectClient(clientId));
  selectFilteredClients$ = (properties: Record<string, string[]> | null) =>
    this.store.select(selectFilteredClients(properties));
  selectClientList$ = (status: ClientStatus) =>
    this.store.select(selectClientList(status));
  selectClientFilterOptions$ = (keys: string[]) =>
    this.store.select(selectClientFilterOptions({ keys }));
  selectCompanies$ = (clientId: string) =>
    this.store.select(selectCompanies(clientId));
  selectGeos$ = (clientId: string) => this.store.select(selectGeos(clientId));
  selectArchetypes$ = (clientId: string) =>
    this.store.select(selectArchetypes(clientId));
  selectSites$ = (clientId: string) => this.store.select(selectSites(clientId));
  selectGeosAndSites$ = (clientId: string) =>
    this.store.select(selectGeosAndSites(clientId));
  selectSectors$ = (clientId: string) =>
    this.store.select(selectSectors(clientId));
  selectQuantities$ = (clientId: string) =>
    this.store.select(selectQuantities(clientId));
  selectFluids$ = (clientId: string) =>
    this.store.select(selectFluids(clientId));
  selectProcesses$ = (clientId: string) =>
    this.store.select(selectProcesses(clientId));
  selectConverters$ = (clientId: string) =>
    this.store.select(selectConverters(clientId));
  selectMetrics$ = (clientId: string) =>
    this.store.select(selectMetrics(clientId));
  selectScalingFactors$ = (clientId: string) =>
    this.store.select(selectScalingFactors(clientId));
  selectTimeSeries$ = (clientId: string) =>
    this.store.select(selectTimeSeries(clientId));
  selectTrajectories$ = (clientId: string) =>
    this.store.select(selectTrajectories(clientId));
  selectTrajectoryVariations$ = (clientId: string) =>
    this.store.select(selectTrajectoryVariations(clientId));
  selectConstraints$ = (clientId: string) =>
    this.store.select(selectConstraints(clientId));
  selectFlowPrices$ = (clientId: string) =>
    this.store.select(selectFlowPrices(clientId));
  selectFlowVolumes$ = (clientId: string) =>
    this.store.select(selectFlowVolumes(clientId));
  selectRenewables$ = (clientId: string) =>
    this.store.select(selectRenewables(clientId));
  selectStorages$ = (clientId: string) =>
    this.store.select(selectStorages(clientId));
  selectExistingAssets$ = (clientId: string) =>
    this.store.select(selectExistingAssets(clientId));
  selectExistingContracts$ = (clientId: string) =>
    this.store.select(selectExistingContracts(clientId));
  dataLoading$ = (clientId: string) =>
    this.store.select(selectDataLoading(clientId));
  dataLoaded$ = (clientId: string) =>
    this.store.select(selectDataLoaded(clientId));
  shareDataLoading$ = (clientId: string) =>
    this.store.select(selectShareDataLoading(clientId));
  clientLoading$ = (clientId: string) =>
    this.store.select(selectClientLoading(clientId));
  selectCompanyHierarchy$ = (clientId: string) =>
    this.store.select(selectCompanyHierarchy(clientId));
  selectIsIneligibleForComputation$ = (
    clientId: string,
    includeCampaigns: boolean = false,
  ) =>
    this.store.select(
      selectIsIneligibleForComputation(clientId, includeCampaigns),
    );
  selectIneligibleCampaignsForComputation$ = (clientId: string) =>
    this.store.select(selectIneligibleCampaignsForComputation(clientId));
  selectRunSettings$ = (clientId: string): Observable<RunSettings> =>
    this.store.select(selectRunSettings(clientId));
  selectGeographyGroups$ = (clientId: string): Observable<GroupUnitEntity> =>
    this.store.select(selectGeographyGroups(clientId));
  selectRootGeography$ = (clientId: string) =>
    this.store.select(selectRootGeography(clientId));
  selectGeographyTree$ = (
    clientId: string,
    geoId: string,
  ): Observable<ClientTreeNode[]> =>
    this.store.select(selectGeographyTree({ clientId, geoId }));
  selectGeoFlatMemberNames$ = (
    clientId: string,
  ): Observable<Record<string, string[]>> =>
    this.store.select(selectGeoFlatMemberNames(clientId));
  selectCompanyFlatMemberNames$ = (
    clientId: string,
  ): Observable<Record<string, string[]>> =>
    this.store.select(selectCompanyFlatMemberNames(clientId));
  /**
   * An observable of groups for filtering options.
   * @param clientId - the client id
   * @param groupKey - the group key
   * @returns the selected group from the client store
   */
  private groups$ = (clientId: string, groupKey: GroupType) =>
    this.store.select(selectGroups({ clientId, groupKey }));

  /**
   * An observable of company group for filtering options.
   * @param clientId - the client id
   * @returns the selected company group from the client store
   */
  companyGroups$ = (clientId: string) =>
    this.groups$(clientId, COMPANY_GROUPS_KEY);

  /**
   * An observable of geography group for filtering options.
   * @param clientId - the client id
   * @returns the selected geography group from the client store
   */
  geoGroups$ = (clientId: string) => this.groups$(clientId, GEO_GROUPS_KEY);

  public selectGeoHierarchy$ = (clientId: string) =>
    this.store.select(selectGeoHierarchy(clientId));

  public selectGeoHierarchyWOSites$ = (clientId: string) =>
    this.store.select(selectGeoHierarchyWOSites(clientId));

  selectActiveClient(clientId: string) {
    this.store.dispatch(ClientActions.selectActiveClient({ clientId }));
  }

  create(name: string, description?: string) {
    this.store.dispatch(ClientActions.create({ name, description }));
  }

  update(clientId: string, name: string, description?: string) {
    this.store.dispatch(ClientActions.update({ clientId, name, description }));
  }

  get(clientId: string) {
    this.store.dispatch(ClientActions.get({ clientId }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === ClientActions.getSuccess.type),
    );
  }

  getList() {
    this.store.dispatch(ClientActions.getList());
  }

  share(clientId: string, shareInfo: ClientShareInfo[]) {
    this.store.dispatch(ClientActions.share({ clientId, shareInfo }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === ClientActions.shareSuccess.type),
    );
  }

  getClientShareInfo(clientId: string) {
    this.store.dispatch(ClientActions.getClientShareInfo({ clientId }));
  }

  getClientDataInfo(clientId: string) {
    this.store.dispatch(ClientActions.getClientDataInfo({ clientId }));
  }

  delete(clientId: string) {
    this.store.dispatch(ClientActions.deleteClient({ clientId }));
  }

  removeActiveClient(clientId: string) {
    window.localStorage.setItem(ACTIVE_CLIENT_ID, null);
    this.store.dispatch(ClientActions.removeActiveClient({ clientId }));
  }

  setActiveClientAndReloadPage(clientId: string) {
    localStorage.set(ACTIVE_CLIENT_ID, clientId);
    // this is reset the stores

    window.location.assign(`/clients/${clientId}/data`);
  }

  updateOngoingExport(clientId: string, flag: boolean) {
    this.store.dispatch(ClientActions.updateOngoingExport({ clientId, flag }));
  }

  setClientStatus(clientId: string, status: ClientStatus) {
    this.store.dispatch(
      status === 'archive'
        ? ClientActions.archiveClient({ clientId })
        : ClientActions.unarchiveClient({ clientId }),
    );
  }

  cascadeUpdate(data: Partial<Client>) {
    this.store.dispatch(
      ClientActions.cascadeUpdate({
        clientId: data.clientId,
        updatedBy: data.updatedBy,
        updatedAt: formatDate(data.updatedAt, 'dd MMM YYYY HH:mm', this.locale),
      }),
    );
  }

  getMainEntitiesReference() {
    return this.activeClientId$.pipe(
      skipWhile((_cId) => !_cId),
      first(),
      mergeMap((clientId) =>
        combineLatest([
          this.selectCompanies$(clientId).pipe(map(generateKeyNameReference)),
          this.selectGeos$(clientId).pipe(map(generateKeyNameReference)),
          this.selectSectors$(clientId).pipe(map(generateKeyNameReference)),
          this.selectFluids$(clientId).pipe(map(generateKeyNameReference)),
          this.selectProcesses$(clientId).pipe(map(generateKeyNameReference)),
        ]).pipe(
          map(([companyEntityId, geoId, sectorId, fluidId, processId]) => ({
            companyEntityId,
            geoId,
            sectorId,
            fluidId,
            processId,
          })),
        ),
      ),
    );
  }

  /**
   * Refreshes the client info when the updated campaign is ineligible.
   * @param campaignId
   * @param clientId
   */
  refreshClientForIneligibleCampaign(campaignIds: string[], clientId: string) {
    this.selectIneligibleCampaignsForComputation$(clientId)
      .pipe(
        first(),
        tap((ineligibleCampaigns) =>
          campaignIds.some((id) => {
            if (ineligibleCampaigns?.includes(id)) {
              this.get(clientId);
              return true;
            }
          }),
        ),
      )
      .subscribe();
  }

  selectFilterOptionsWithHierarchy(clientId: string): Observable<any> {
    return combineLatest([
      this.selectGeos$(clientId),
      this.selectSites$(clientId),
      this.filterOptionsHierarchyPipe(
        this.selectCompanyHierarchy$(clientId),
        this.companyGroups$(clientId),
        COMPANY_KEY,
      ),
    ]).pipe(
      map(([geographies, sites, company]) => {
        return {
          geography: this.getNames(geographies),
          site: this.getNames(sites),
          ...company,
        };
      }),
    );
  }

  private getNames(
    entities: ClientDataEntity<Geography | Site>,
  ): Array<string> {
    return Object.values(Coerce.toEmptyObject(entities)).map(
      (entity) => entity.name,
    );
  }

  private filterOptionsHierarchyPipe(
    hierarchy: Observable<Hierarchy>,
    group: Observable<Groups>,
    key: string,
  ) {
    return combineLatest([hierarchy, group]).pipe(
      filter((data) => data.every((datum) => !!datum)),
      map(([hierDatum, grpDatum]) =>
        this.getGroupedFilters(key, hierDatum, grpDatum),
      ),
    );
  }

  private getGroupedFilters(
    key: string,
    hierarchy: Hierarchy,
    groups: Groups,
  ): HierarchyFilterRecord {
    return {
      [key]: Coerce.getObjKeys(hierarchy).map((name) => ({
        name,
        values: Coerce.getObjKeys(hierarchy?.[name])
          .sort((a, b) => a.localeCompare(b))
          .concat(
            Coerce.getObjKeys(groups?.[name]).map(
              (val) => `${GROUP_INDICATOR}${val}`,
            ),
          ),
      })),
    };
  }

  selectFilterGroupedNames(clientId: string): Observable<GroupedFilterNames> {
    return combineLatest([
      this.selectGeoFlatMemberNames$(clientId).pipe(distinctObject),
      this.selectCompanyFlatMemberNames$(clientId).pipe(distinctObject),
    ]).pipe(
      map(([geo, company]) => ({
        [GEOGRAPHY_KEY]: geo,
        [COMPANY_KEY]: company,
        [ENTITY_KEY]: company,
      })),
    );
  }

  getConstraintsOfActiveClient() {
    return this.activeClientId$.pipe(
      skipWhile((_cId) => !_cId),
      first(),
      switchMap((clientId) => this.selectConstraints$(clientId)),
    );
  }

  /**
   * Retrieves the sites from the given parameters.
   *
   * @param clientId client id.
   * @param isGeoGroup True if need to retrieve from geo groups.
   * @param cgeoId geoGroup or geo.
   */
  getSites(
    clientId: string,
    isGeoGroup: boolean,
    cgeoId: string,
  ): Observable<Record<string, any>[]> {
    return combineLatest([
      this.selectGeos$(clientId),
      this.selectGeographyGroups$(clientId),
    ]).pipe(
      map(([geographies, groups]) => {
        const hids = isGeoGroup
          ? groups?.[cgeoId]?.geoIds?.map((geoId) => geographies?.[geoId]?.hId)
          : [geographies[cgeoId]?.hId];

        return getLeavesFromHids(geographies, hids);
      }),
    );
  }

  /**
   * Retrieves a list of sites for a given client.
   *
   * @param clientId - The ID of the client.
   * @param isGeoGroup - A boolean indicating if the sites are part of a geographical group.
   * @param cgeoId - The ID of the geographical group.
   * @returns An Observable that emits an array of records, where each record represents a site.
   */
  getSitesAsList(
    clientId: string,
    isGeoGroup: boolean,
    campaignGeoId: string,
  ): Observable<Record<string, any>[]> {
    return this.getSites(clientId, isGeoGroup, campaignGeoId).pipe(
      map((sites) => Coerce.getObjValues(sites)),
    );
  }

  /**
   * Retrieves the sites from the given parameters.
   *
   * @param clientId client id.
   * @param campaignGeoId geoGroup or geo.
   */
  getSitesV7(
    clientId: string,
    campaignGeoId: string,
  ): Observable<Record<string, any>[]> {
    return combineLatest([
      this.selectGeos$(clientId),
      this.selectSites$(clientId),
    ]).pipe(
      map(([clientGeographies, sites]) => {
        return Object.values(sites).filter((site) =>
          site.parentHid.startsWith(clientGeographies[campaignGeoId].hId),
        );
      }),
    );
  }

  /**
   * Retrieves the ordered geo path
   *
   * @param clientId client id.
   * @param geoName geo name
   * @returns array from root to geo
   */
  getGeoPath$(
    clientId: string,
    geoName: string,
  ): Observable<ClientDataEntity[]> {
    return this.selectGeos$(clientId).pipe(
      map((geos) => {
        let keyGeoId: string;
        let geoPath: ClientDataEntity[] = [];
        // initial lookup of geoName's geoId
        for (const geoId of Coerce.getObjKeys(geos)) {
          if (geos[geoId].name === geoName) {
            keyGeoId = geoId;
            break;
          }
        }

        while (!!keyGeoId) {
          geoPath.unshift(geos[keyGeoId]);
          keyGeoId = geos[keyGeoId]?.parentId || '';
        }
        return geoPath;
      }),
    );
  }

  getTrajectoryVariations(clientId: string) {
    return this.dataLoaded$(clientId).pipe(
      skipWhile((loaded) => !loaded),
      mergeMap(() => this.selectTrajectoryVariations$(clientId)),
      first(),
    );
  }

  duplicateClient(clientId: string) {
    this.store.dispatch(ClientActions.duplicateClient({ clientId }));
  }

  updateDuplicateStatus(
    clientId: string,
    dupClientId: string,
    status: string,
    isSandbox: boolean = false,
  ) {
    this.store.dispatch(
      ClientActions.updateDuplicateStatus({
        clientId,
        dupClientId,
        status,
        isSandbox,
      }),
    );
  }

  quickSwitch(
    clientId: string,
    hasSuccessfulImport: boolean,
    clientName: string,
  ) {
    this.store.dispatch(
      ClientActions.quickSwitch({ clientId, hasSuccessfulImport, clientName }),
    );
  }
}

import {
  CampaignFacadeService,
  PricingMethod,
  SizingMethod,
} from 'ssotool-app/+campaign/store';
import { ClientFacadeService } from 'ssotool-app/+client/store';
import { REFERENCES } from 'ssotool-app/app.references';
import {
  getEnumKeyByValue,
  mapEnumToOptions,
} from 'ssotool-app/shared/helpers/form-helpers';
import { convertToYearlyValues } from 'ssotool-app/shared/helpers/time-dependent';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import { FormService } from 'ssotool-app/shared/services/form/form.service';

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';

import { CampaignBaseFormComponent } from '../base/campaign-base-form.component';
import { getProcessLabelForCampaign } from '../campaign-drawer.utils';

@Component({
  selector: 'sso-drawer-market',
  templateUrl: './market-form.component.html',
  styleUrls: ['./market-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketFormComponent
  extends CampaignBaseFormComponent
  implements OnInit
{
  campaignType = REFERENCES.market;
  defaultValues = {
    minVolume: convertToYearlyValues('0.0', this.startYear, this.endYear),
    maxVolume: convertToYearlyValues('0.0', this.startYear, this.endYear),
    duration: 5,
    co2RateScope1: convertToYearlyValues('0.0', this.startYear, this.endYear),
    co2RateScope2: convertToYearlyValues('0.0', this.startYear, this.endYear),
    co2RateScope3: convertToYearlyValues('0.0', this.startYear, this.endYear),
    indivisibleCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
    fixedPrice: convertToYearlyValues('0.0', this.startYear, this.endYear),
    tax: convertToYearlyValues('0.0', this.startYear, this.endYear),
    pricingMethod: getEnumKeyByValue(PricingMethod, PricingMethod.lock_in),
    sizingMethod: getEnumKeyByValue(SizingMethod, SizingMethod.variable_volume),
  };

  baseForm = this._formBuilder.group({
    ...this.commonControls,
    campaignType: 'market',
    processId: ['', Validators.required],
    fluidId: '',
    minVolume: [this.defaultValues.minVolume, Validators.required],
    maxVolume: [this.defaultValues.maxVolume, Validators.required],
    duration: [this.defaultValues.duration, Validators.required],
    co2RateScope1: [this.defaultValues.co2RateScope1, Validators.required],
    co2RateScope2: [this.defaultValues.co2RateScope2, Validators.required],
    co2RateScope3: [this.defaultValues.co2RateScope3, Validators.required],
    indivisibleCost: [this.defaultValues.indivisibleCost, Validators.required],
    fixedPrice: [this.defaultValues.fixedPrice, Validators.required],
    tax: [this.defaultValues.tax, Validators.required],
    pricingMethod: [this.defaultValues.pricingMethod, Validators.required],
    sizingMethod: [this.defaultValues.sizingMethod, Validators.required],
  });

  processLabel = getProcessLabelForCampaign();

  get isInputSimplified(): boolean {
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE);
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _formBuilder: FormBuilder,
    formService: FormService,
    clientFacade: ClientFacadeService,
    campaignFacade: CampaignFacadeService,
  ) {
    super(ngControl, clientFacade, campaignFacade, formService);
  }

  pricingMethodOptions = mapEnumToOptions(PricingMethod);
  sizingMethodOptions = mapEnumToOptions(SizingMethod);
}

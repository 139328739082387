import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

export function getProcessLabel(): string {
  if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE_R2)) {
    return 'Entities.labels.parameters.energyProcessId';
  } else {
    return 'Entities.labels.parameters.processId';
  }
}

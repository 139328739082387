import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService, ExistingContract } from 'ssotool-app/+client';
import { ExistingContractDrawerComponent } from 'ssotool-app/+entities/components/drawer';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import { Coerce } from 'ssotool-shared';

import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

import {
  ExistingContractDrawerData,
  ExistingContractDrawerMode,
} from '../../components/drawer/existing-contract-drawer/existing-contract-drawer.model';

const COLUMNS = [
  { name: 'name', mode: 'event' },
  { name: 'geography' },
  { name: 'entity' },
  { name: 'sector' },
  { name: 'process' },
  { name: 'fluid' },
];

const COLUMNS_V7 = [
  { name: 'name', mode: 'event' },
  { name: 'geography', label: 'site/geography' },
  { name: 'scalingFactorName', label: 'Scaling Factor' },
  {
    name: 'process',
    label: isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE_R2)
      ? 'Energy Process'
      : 'Process',
  },
  { name: 'fluid' },
];
@UntilDestroy()
@Component({
  selector: 'sso-existing-contracts-list',
  templateUrl: './existing-contracts-list.component.html',
  styleUrls: ['./existing-contracts-list.component.scss'],
})
export class ExistingContractsListComponent implements OnInit {
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
    ? COLUMNS_V7
    : COLUMNS;

  constructor(
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          this.clientId = client.clientId;
          this.entities$ = this.clientFacade
            .selectExistingContracts$(client.clientId)
            .pipe(
              skipWhile((data) => !data),
              map((data) =>
                Coerce.getObjValues(data).map((contract) => ({
                  ...contract,
                  name: contract.name,
                  geography: contract.geoName || contract.siteName,
                  scalingFactorName: contract.scalingFactorName,
                  entity: contract.companyName,
                  sector: contract.sectorName,
                  fluid: contract.fluidName,
                  process: contract.processName,
                })),
              ),
            );
          this.loading$ = this.clientFacade.dataLoading$(client.clientId);
        }
      });
  }

  openDrawer(existingContract: ExistingContract) {
    this.drawerService.open<
      ExistingContractDrawerComponent,
      ExistingContractDrawerData,
      unknown
    >(ExistingContractDrawerComponent, {
      data: {
        mode: ExistingContractDrawerMode.VIEW,
        clientId: this.clientId,
        existingContract,
        readonly: true,
      },
      width: '50vw',
      closeIcon: false,
      overrideClose: true,
    });
  }
}

<sso-title-bar
  [titlePage]="'Campaign.labels.title' | translate"
  icon="campaign"
  class="sso-title-bar"
>
</sso-title-bar>
<div titleBarActions fxLayout="row" fxLayoutAlign="space-between">
  <form [formGroup]="form">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <sso-search-input
        fxLayoutAlign="start center"
        formControlName="searchKey"
        [label]="'Search'"
        [placeholder]="'Search'"
        data-testid="search-input"
      ></sso-search-input>
      <sso-filters
        data-testid="filter-input"
        [options]="filterOptions$ | async"
        formControlName="filters"
        [stripLabel]="stripLabel"
        [mode]="'dialog'"
        [disable]="loading"
        [title]="'Campaign.labels.title' | translate"
        [dialogConfig]="filtersDialogConfig"
      ></sso-filters>
    </div>
  </form>

  <div *ngIf="isPermitted" fxLayoutAlign="end center">
    <button
      data-testid="create-campaign-button"
      style="line-height: 4.1"
      mat-button
      type="button"
      (click)="addCampaign()"
      color="primary"
      [disabled]="loading || hasOngoingImport"
    >
      <mat-icon matPrefix>add</mat-icon>
      <b>Create Campaign</b>
    </button>
    <button
      data-testid="populate-campaign-button"
      style="line-height: 4.1"
      mat-button
      type="button"
      (click)="populateWithLibrary()"
      color="primary"
      [disabled]="loading || hasOngoingImport"
    >
      <mat-icon matPrefix [style.margin-right.px]="3">library_add</mat-icon>
      <b matTooltip="Populate with Library">Populate</b>
    </button>
    <button
      *ngIf="!isSingleInputFeatureEnabled"
      data-testid="import-btn"
      style="line-height: 4.1"
      mat-button
      type="button"
      color="primary"
      [disabled]="loading"
      (click)="openImportList()"
    >
      <mat-icon matPrefix>upload_file</mat-icon>
      <b>Import</b>
    </button>

    <button
      data-testid="action-menu-button"
      fxLayoutAlign="end center"
      style="line-height: 4.1"
      mat-icon-button
      type="button"
      color="primary"
      [matMenuTriggerFor]="editMenu"
      [disabled]="loading || isSelected() || hasOngoingImport"
    >
      <mat-icon matPrefix>more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #editMenu>
    <button
      data-testid="batch-export-button"
      mat-menu-item
      type="button"
      [disabled]="
        loading || isSelected() || hasOngoingExport || hasOngoingImport
      "
      (click)="exportSelection()"
    >
      <mat-icon matPrefix>drive_file_move</mat-icon>
      <span>Export</span>
    </button>
    <button
      data-testid="batch-duplicate-button"
      mat-menu-item
      (click)="duplicateSelection()"
      [disabled]="selection?.selected?.length < 1"
    >
      <mat-icon>control_point_duplicate</mat-icon>
      <span>Duplicate</span>
    </button>
    <button
      data-testid="batch-delete-button"
      mat-menu-item
      (click)="deleteSelection()"
      [disabled]="selection?.selected?.length < 1"
    >
      <mat-icon>delete_forever</mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</div>
<div *ngIf="hasOngoingImport" class="ongoing-process" fxLayoutAlign="flex-end">
  <mat-icon matPrefix [style.margin-right.px]="3"
    >notification_important</mat-icon
  >
  <b>{{ 'Campaign.messages.hasOngoingImport' | translate }}</b>
</div>

<div>
  <sso-chip-list
    data-testid="filter-chips"
    [formControl]="chipForm"
    [stripLabel]="stripLabel"
  ></sso-chip-list>
</div>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-table class="sso-stripe-table" [dataSource]="datasource$ | async" matSort>
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        data-testid="master-checkbox"
        color="primary"
        (change)="$event ? masterToggle() : null"
        [checked]="selection?.hasValue() && isAllSelected$ | async"
        [indeterminate]="selection?.hasValue() && isNotAllSelected$ | async"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox
        data-testid="single-checkbox"
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
      <button
        class="warning-message"
        [hidden]="isEligibleWarningHidden(row)"
        mat-icon-button
        type="button"
        [disabled]="true"
      >
        <mat-icon
          matPrefix
          [matTooltip]="'Campaign.messages.ineligibleTooltip' | translate"
          >warning_amber</mat-icon
        >
      </button>
    </mat-cell>
  </ng-container>
  <ng-container
    *ngFor="let field of displayedColumns"
    [matColumnDef]="field.name"
  >
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ field.label | titlecase }}
    </mat-header-cell>
    <mat-cell [ngSwitch]="field.name" *matCellDef="let row">
      <div *ngSwitchCase="'type'" class="chip-container">
        <mat-chip-listbox *ngIf="!row.loading; else skeletonLoader">
          <mat-chip-option
            [ngStyle]="{ 'background-color': COLOR[row[field.name]] }"
            selected
            >{{ row[field.name] }}</mat-chip-option
          >
        </mat-chip-listbox>
      </div>
      <div *ngSwitchCase="'name'" class="sso-link">
        <div *ngIf="!row.loading; else skeletonLoader">
          {{ row[field.name] || '-' }}
        </div>
      </div>
      <div *ngSwitchDefault>
        <div *ngIf="!row.loading; else skeletonLoader">
          {{ row[field.name] || '-' }}
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnDefinitions"></mat-header-row>
  <mat-row
    class="sso-clickable"
    *matRowDef="let row; columns: columnDefinitions"
    (click)="onRowClick(row)"
  ></mat-row>
</mat-table>

<ng-template #skeletonLoader>
  <ngx-skeleton-loader
    count="1"
    animation="progress"
    [theme]="{ margin: '12px 0', height: '20px', width: '100px' }"
  ></ngx-skeleton-loader>
</ng-template>

<div *ngIf="!loading && (noData$ | async)" class="common-table-empty">
  {{ 'Generic.messages.noDisplay' | translate }}
</div>

<mat-paginator pageSize="20" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>

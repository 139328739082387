<form fxLayout="column" fxLayoutGap="20px" [formGroup]="baseForm" #form>
  <div fxLayout="column" fxLayoutGap="10px">
    <!-- General Parameters -->
    <div class="general-container" fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon
          fxFlex="18px"
          class="campaign-type-icon"
          data-testid="campaign-type-icon"
          [ngStyle]="{ color: COLOR[campaignType] }"
          [matTooltip]="campaignType"
        >
          circle
        </mat-icon>
        <!-- Name -->
        <sso-input
          class="full-width"
          appearance="fill"
          size="large"
          data-testid="input-name-field"
          [placeholder]="'Campaign.placeholders.parameters.name' | translate"
          [label]="'Campaign.labels.parameters.name' | translate"
          [formControl]="baseForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages?.name"
          [readonly]="readonly"
        ></sso-input>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- SubType -->
        <sso-input
          data-testid="input-subtype-field"
          [placeholder]="'Campaign.placeholders.parameters.subtype' | translate"
          [label]="'Campaign.labels.parameters.subtype' | translate"
          [formControl]="baseForm?.controls?.subtype"
          [required]="true"
          [errorMessageMap]="errorMessages?.subtype"
          [readonly]="readonly"
        ></sso-input>
        <!-- scale -->
        <sso-input
          data-testid="input-scale-field"
          [placeholder]="'Campaign.placeholders.parameters.scale' | translate"
          [label]="'Campaign.labels.parameters.scale' | translate"
          [formControl]="baseForm?.controls?.scale"
          [required]="true"
          [errorMessageMap]="errorMessages?.scale"
          [readonly]="readonly"
        ></sso-input>
        <!-- Category -->
        <sso-input
          data-testid="input-category-field"
          [placeholder]="
            'Campaign.placeholders.parameters.category' | translate
          "
          [label]="'Campaign.labels.parameters.category' | translate"
          [formControl]="baseForm?.controls?.category"
          [required]="true"
          [errorMessageMap]="errorMessages?.category"
          [readonly]="readonly"
        ></sso-input>
        <!-- Roadmap -->
        <sso-input
          data-testid="input-roadmap-field"
          [placeholder]="'Campaign.placeholders.parameters.roadmap' | translate"
          [label]="'Campaign.labels.parameters.roadmap' | translate"
          [formControl]="baseForm?.controls?.pathway"
          [required]="true"
          [errorMessageMap]="errorMessages?.roadmap"
          [readonly]="readonly"
        ></sso-input>
        <!-- Renewal Strategy -->
        <sso-select
          data-testid="input-renewalStrategy-field"
          [label]="'Campaign.labels.parameters.renewalStrategy' | translate"
          [options]="renewalStrategyOptions"
          [control]="baseForm?.controls?.renewalStrategy"
          [errorMessageMap]="errorMessages?.renewalStrategy"
          [required]="true"
          [readonly]="readonly"
        ></sso-select>
      </div>
    </div>
    <!-- Associated Entities -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.associatedEntities' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Geography -->
        <sso-select
          data-testid="select-geography"
          [label]="'Campaign.labels.parameters.geoId' | translate"
          [options]="geoOptions$ | async"
          [control]="baseForm?.controls?.geoId"
          [errorMessageMap]="errorMessages?.geoId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Company Entity -->
        <sso-select
          data-testid="select-company"
          [label]="'Campaign.labels.parameters.companyId' | translate"
          [options]="companyOptions$ | async"
          [control]="baseForm?.controls?.companyId"
          [errorMessageMap]="errorMessages?.companyId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Sector -->
        <sso-select
          data-testid="select-sector"
          [label]="'Campaign.labels.parameters.sectorId' | translate"
          [options]="sectorOptions$ | async"
          [control]="baseForm?.controls?.sectorId"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Fluid -->
        <sso-select
          data-testid="select-fluid"
          [label]="'Campaign.labels.parameters.fluidId' | translate"
          [options]="fluidOptions$ | async"
          [control]="baseForm?.controls?.fluidId"
          [errorMessageMap]="errorMessages?.fluidId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
        <!-- Process -->
        <sso-select
          data-testid="select-process"
          [label]="processLabel | translate"
          [options]="processOptions$ | async"
          [control]="baseForm?.controls?.processId"
          [errorMessageMap]="errorMessages?.processId"
          [required]="true"
          [readonly]="readonly"
        >
        </sso-select>
      </div>
    </mat-expansion-panel>
    <!-- Main Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.main' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Min Capacity -->
        <sso-yearly-input
          data-testid="input-minCapacity"
          formControlName="minCapacity"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.minCapacity'"
          [label]="'Campaign.labels.parameters.minCapacity' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Max Capacity -->
        <sso-yearly-input
          data-testid="input-maxCapacity"
          formControlName="maxCapacity"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.maxCapacity'"
          [label]="'Campaign.labels.parameters.maxCapacity' | translate"
          [readonly]="readonly"
          [warning]="
            flagWarnings(baseForm?.controls?.maxCapacity?.value, startYear)
          "
          [warningMessage]="'Campaign.messages.zeroValueWarning' | translate"
        ></sso-yearly-input>
        <!-- E/P Ratio -->
        <sso-yearly-input
          data-testid="input-epRatio"
          formControlName="epRatio"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.epRatio'"
          [label]="'Campaign.labels.parameters.epRatio' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Max Dod -->
        <sso-yearly-input
          data-testid="input-maxDod"
          formControlName="maxDod"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.maxDod'"
          [label]="'Campaign.labels.parameters.maxDod' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Roundtrip Efficiency -->
        <sso-yearly-input
          data-testid="input-roundtripEfficiency"
          formControlName="roundtripEfficiency"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.roundtripEfficiency'"
          [label]="'Campaign.labels.parameters.roundtripEfficiency' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Technical life -->
        <sso-yearly-input
          data-testid="input-technicalLife"
          formControlName="technicalLife"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.technicalLife'"
          [label]="'Campaign.labels.parameters.technicalLife' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
        <!-- Depreciation time -->
        <sso-yearly-input
          data-testid="input-depreciationTime"
          formControlName="depreciationTime"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.depreciationTime'"
          [label]="'Campaign.labels.parameters.depreciationTime' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
        <!-- Implementation time -->
        <sso-yearly-input
          data-testid="input-implementationTime"
          formControlName="implementationTime"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.implementationTime'"
          [label]="'Campaign.labels.parameters.implementationTime' | translate"
          [readonly]="readonly"
          [isNonNegativeInteger]="true"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
    <!-- Emission Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.emission' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Build CO2 Scope 3 Emissions 1 -->
        <sso-yearly-input
          data-testid="input-buildCo2Scope3Emissions1"
          formControlName="buildCo2Scope3Emissions1"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCo2Scope3Emissions1'"
          [label]="
            'Campaign.labels.parameters.buildCo2Scope3Emissions1' | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Build CO2 Scope 3 Emissions 2 -->
        <sso-yearly-input
          data-testid="input-buildCo2Scope3Emissions2"
          formControlName="buildCo2Scope3Emissions2"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCo2Scope3Emissions2'"
          [label]="
            'Campaign.labels.parameters.buildCo2Scope3Emissions2' | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible CO2 Scope 3 Emissions -->
        <sso-yearly-input
          data-testid="input-indivisibleCo2Scope3Emissions"
          formControlName="indivisibleCo2Scope3Emissions"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="
            'Campaign.messages.errors.indivisibleCo2Scope3Emissions'
          "
          [label]="
            'Campaign.labels.parameters.indivisibleCo2Scope3Emissions'
              | translate
          "
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
    <!-- Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Campaign.labels.cost' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- Build cost 1 -->
        <sso-yearly-input
          data-testid="input-buildCost1"
          formControlName="buildCost1"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCost1'"
          [label]="'Campaign.labels.parameters.buildCost1' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Build cost 2 -->
        <sso-yearly-input
          data-testid="input-buildCost2"
          formControlName="buildCost2"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.buildCost2'"
          [label]="'Campaign.labels.parameters.buildCost2' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- Indivisible cost -->
        <sso-yearly-input
          data-testid="input-indivisibleCost"
          formControlName="indivisibleCost"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.indivisibleCost'"
          [label]="'Campaign.labels.parameters.indivisibleCost' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M 1 -->
        <sso-yearly-input
          data-testid="input-foM1"
          formControlName="foM1"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foM1'"
          [label]="'Campaign.labels.parameters.foM1' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M 2 -->
        <sso-yearly-input
          data-testid="input-foM2"
          formControlName="foM2"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foM2'"
          [label]="'Campaign.labels.parameters.foM2' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
        <!-- FO&M per install -->
        <sso-yearly-input
          data-testid="input-foMPerInstall"
          formControlName="foMPerInstall"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="'Campaign.messages.errors.foMPerInstall'"
          [label]="'Campaign.labels.parameters.foMPerInstall' | translate"
          [readonly]="readonly"
        ></sso-yearly-input>
      </div>
    </mat-expansion-panel>
  </div>

  <button #submit type="submit" [hidden]="true"></button>
</form>

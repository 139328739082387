import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  Client,
  ClientFacadeService,
  ExistingContract,
} from 'ssotool-app/+client';
import {
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
} from 'ssotool-app/app.references';
import { BaseFormComponent } from 'ssotool-app/shared/component/base-form/base-form.component';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import {
  convertToYearlyValues,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'ssotool-shared';

import { Component, Inject, OnInit, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  DetailsDrawerService,
  OCULUS_DETAILS_DRAWER_DATA,
} from '@oculus/components/details-drawer';

import { getProcessLabel } from '../existing-drawer.utils';
import {
  ExistingContractDrawerData,
  ExistingContractDrawerMode,
} from './existing-contract-drawer.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-existing-contract-drawer',
  templateUrl: './existing-contract-drawer.component.html',
  styleUrls: ['./existing-contract-drawer.component.scss'],
})
export class ExistingContractDrawerComponent
  extends BaseFormComponent
  implements OnInit
{
  startYear = DEFAULT_START_YEAR;
  endYear = DEFAULT_END_YEAR;
  baseForm: FormGroup = this.formBuilder.group({
    contractId: '',
    clientId: '',
    owner: '',
    name: ['', Validators.required],
    geoId: [''],
    siteId: [''],
    companyEntityId: ['', Validators.required],
    sectorId: '',
    processId: ['', Validators.required],
    fluidId: '',
    scalingFactorName: '',
    startYear: DEFAULT_START_YEAR,
    endYear: DEFAULT_END_YEAR,
    volume: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    duration: [null, Validators.required],
    co2RateScope1: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    co2RateScope2: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    co2RateScope3: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    fixedPrice: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    indivisibleCost: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    tax: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
  });
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Entities.messages.errors');
  fluidOptions$ = new Observable<FormFieldOption<string>[]>();
  geoOptions$ = new Observable<FormFieldOption<string>[]>();
  companyOptions$ = new Observable<FormFieldOption<string>[]>();
  sectorOptions$ = new Observable<FormFieldOption<string>[]>();
  processOptions$ = new Observable<FormFieldOption<string>[]>();

  get hasScalingFactor(): boolean {
    return !!this.baseForm.controls.scalingFactorName.value;
  }

  private _mode = new BehaviorSubject<ExistingContractDrawerMode>(
    ExistingContractDrawerMode.VIEW,
  );
  mode$ = this._mode.asObservable();

  readonly$ = this.mode$.pipe(
    startWith(ExistingContractDrawerMode.VIEW),
    map(
      (mode) => this.data.readonly || mode === ExistingContractDrawerMode.VIEW,
    ),
  );
  loading$ = this.clientFacade.dataLoading$(this.data.clientId);

  processLabel = getProcessLabel();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    @Inject(OCULUS_DETAILS_DRAWER_DATA)
    public data: ExistingContractDrawerData,
    public formBuilder: FormBuilder,
    private _formService: FormService,
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {
    super(ngControl);
  }

  get isPristine() {
    return this.baseForm.pristine;
  }

  get isSimplifiedFeatureEnabled() {
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE);
  }

  get isSiteLevel() {
    return !!this.data.existingContract.siteId;
  }

  ngOnInit() {
    super.ngOnInit();
    this._mode.next(this.data.mode);

    this.initForm();
    this.initFieldOptions();
  }

  initForm() {
    let initializedForm = {
      clientId: this.data.clientId,
    } as Partial<ExistingContract>;
    if (
      this.data?.existingContract &&
      this.data.mode !== ExistingContractDrawerMode.CREATE
    ) {
      initializedForm = {
        ...initializedForm,
        ...this.data.existingContract,
      };
    }
    this.baseForm.patchValue(initializedForm, { emitEvent: false });
  }

  /**
   * Initialized fields that has reference to the client entities.
   */
  private initFieldOptions() {
    const selectorKey = this.data.clientId;
    this.geoOptions$ = this.getGeoOptions(
      selectorKey,
      this.data.existingContract.siteId ? 'siteId' : 'geoId',
    );
    this.companyOptions$ = this.createFormOptions(
      this.clientFacade.selectCompanies$,
      selectorKey,
      'companyEntityId',
    );
    this.sectorOptions$ = this.createFormOptions(
      this.clientFacade.selectSectors$,
      selectorKey,
    );
    this.processOptions$ = this.createFormOptions(
      this.clientFacade.selectProcesses$,
      selectorKey,
    );
    this.fluidOptions$ = this.createFormOptions(
      this.clientFacade.selectFluids$,
      selectorKey,
    );
  }

  private getGeoOptions(
    selectorKey: Client['clientId'],
    geographyAttribute: 'geoId' | 'siteId',
  ): Observable<FormFieldOption<string>[]> {
    if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)) {
      return this.createFormOptions(
        this.clientFacade.selectGeosAndSites$,
        selectorKey,
        geographyAttribute,
      );
    }
  }

  onClose(): void {
    this.drawerService.close();
  }
}
